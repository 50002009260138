.ui.checkbox .box:before,
.ui.checkbox label:before {
  box-shadow: @checkboxBoxShadow;
}

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  box-shadow: @radioBoxShadow;
}

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  box-shadow: none;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  box-shadow: none;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  background: @white !important;
  box-shadow: none !important;
}

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:after {
  top: @checkboxIndeterminateBulletTop;
}

.ui.disabled.checkbox .box,
.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ .box,
.ui.checkbox input[disabled] ~ label,
.ui.checkbox input[disabled]:checked ~ .box,
.ui.checkbox input[disabled]:checked ~ label,
.ui.form .disabled.field .ui.checkbox .box,
.ui.form .disabled.field .ui.checkbox label,
.ui.form .disabled.fields .ui.checkbox .box,
.ui.form .disabled.fields .ui.checkbox label {
  &:before {
    background: @disabledInputBackgroundColor none !important;
    border-color: @disabledInputBorderColor !important;
  }
  &:after {
    color: @disabledCheckboxCheckColor !important;
  }
}

.ui.disabled.radio.checkbox .box,
.ui.disabled.radio.checkbox label,
.ui.radio.checkbox input[disabled] ~ .box,
.ui.radio.checkbox input[disabled] ~ label,
.ui.form .disabled.field .ui.radio.checkbox .box,
.ui.form .disabled.field .ui.radio.checkbox label,
.ui.form .disabled.fields .ui.radio.checkbox .box,
.ui.form .disabled.fields .ui.radio.checkbox label {
  &:after {
    background-color: @disabledCheckboxCheckColor !important;
  }
}

.ui.form .error.field .ui.radio.checkbox .box,
.ui.form .error.field .ui.radio.checkbox label,
.ui.form .error.fields .ui.radio.checkbox .box,
.ui.form .error.fields .ui.radio.checkbox label {
  &:after {
    background-color: @formErrorColor !important;
  }
}

.ui.toggle.checkbox:not(.default-style) {
  &:not(.disabled) {
    input ~ label:before {
      background: @toggleOffLaneColor !important;
    }
    input:checked ~ label:before {
      background: @toggleOnLaneColor !important;
    }
  }
  input ~ label:before {
    content: "OFF";
    text-align: right;
    padding: @toggleLanePadding;
    color: @toggleLaneLabelColor;
    font-size: @toggleLaneLabelFontSize;
  }
  input:checked ~ label:before {
    content: "ON";
    text-align: left;
  }
}
