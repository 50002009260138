@import '../../DatacenterTopology/variables.less';

.topology-collapsed-generics {
  stroke: none;
  fill: @genericBackgroundColor;
  .text {
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    color: @grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
  }
}

.collapsed-generics-fill-pattern-line {
  stroke: @white;
  opacity: 0.15;
}
