@import (reference) '~theme/globals/site.variables';

@structure-block-width: 120px;

.overflown-block {
  flex: 1 1;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 2px fade(@grey, 50%);
  border-radius: 5px;
  padding: 0.5rem;
  background-color: @white;
}

.block-top-label(@title) {
  &:first-child:before {
    content: @title;
    white-space: nowrap;
    position: absolute;
    margin-left: 5px;
    margin-top: -50px;
    font-size: 0.7rem;
    color: fade(@grey, 80%);
    text-shadow: 1px 1px 1px @white;
  }
}

.logical-entity {
  @logical-base-color: desaturate(lighten(@teal, 55%), 40%);
  border-color: darken(@logical-base-color, 10%);
  background-color: @logical-base-color;
}

.physical-entity {
  @physical-base-color: desaturate(lighten(@brown, 35%), 10%);
  border-color: darken(@physical-base-color, 10%);
  background-color: @physical-base-color;
}

.arrowed {
  margin-right: 10px;

  &:after {
    content: "→";
    position: absolute;
    margin-left: @structure-block-width + 10px;
  }
}

.dashed-frame {
  border-radius: 5px;
  border: dashed 1px fade(@grey, 30%);
  background-color: fade(@grey, 10%);
}
