@import (reference) '~theme/globals/site.variables';

.port-map-content {
  overflow-x: auto;
}

.device-summary {
  overflow: auto;
  margin-top: 5px;

  .ui.statistic {
    padding: 5px;
    margin: 3px;
    cursor: default;

    &:first-child {
      padding-left: 0;
    }

    &.hovered {
      background: @darkWhite;
    }

    > .value, > .label {
      text-transform: none;
    }
  }

  .panel-summary {

    .port-map {
      padding-top: 0;

      .port-map-container {
        overflow-x: auto;
        overflow-y: hidden;

        table {
          border-collapse: collapse;

          tr {
            line-height: 1;
          }

          .ui.segment.port {
            cursor: default;
            line-height: 1;
            overflow: visible;
            margin: 0;
            padding: 0;
            background-color: @grey;
            border: 1px solid @white;
            user-select: none;
            border-radius: 0;
          }
        }
      }
    }

    &.compact {
      float: left;
      margin-right: 5px;
      margin-bottom: 5px;
      width: 100%;
    }
  }
}
