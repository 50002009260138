@import (reference) '../src/brandPalette.less';

each(@brandPaletteCustomColors, .(@color, @name) {
  i.@{name}.icon {
    color: @color !important;
  }
  i.inverted.bordered.@{name}.icon,
  i.inverted.circular.@{name}.icon {
    background-color: @color !important;
    color: @white !important;
  }
});
