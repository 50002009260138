@import (reference) '~theme/globals/site.variables';

.graph-popup {
  cursor: default;
  &:hover {
    z-index: 1001 !important;
  }
  .property-name {
    pointer-events: all;
    font-weight: bold;
    color: @blue;
  }
}
