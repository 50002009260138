@import (reference) '~theme/globals/site.variables';

.execute-command-modal {
  .system-info {
    margin-bottom: @18px;
    div {
      display: inline;
    }
    > div {
      > div:first-child {
        font-weight: @bold;
      }
      > div:last-child {
        margin-right: @20px;
      }
    }
  }
  .command-line {
    display: flex;
    margin-bottom: @14px;
    .code-editor-control {
      width: 100%;
      display: inline-flex;
    }
    .ui.dropdown {
      display: inline-flex;
      margin-left: @10px;
      width: 160px;
    }
    .ui.button {
      display: inline-flex;
      margin-left: @10px;
      margin-right: 0;
    }
  }
  .execution-result {
    position: relative;
    .ui.menu {
      position: absolute;
      top: 15px;
      right: 15px;
      display: none;
    }
    &:hover .ui.menu {
      display: block;
    }
    .ui.segment {
      min-height: 300px;
      margin-bottom: 0;
      font-family: monospace;
      white-space: pre;
      overflow: auto;
      &.error {
        outline: 2px solid @red;
        color: lighten(@red, 30%);
        background-color: mix(@black, darken(@red, 30%));
      }
      &.inverted {
        background-color: lighten(@black, 20%);
      }
      > pre {
        margin: 0;
      }
      code.language-xml {
        .hljs-tag {
          color: @blue;
        }
        .hljs-attr {
          color: @blueDown;
        }
        .hljs-string {
          color: @green;
          white-space: break-spaces;
        }
        .hljs-comment {
          color: lighten(@grey, 10);
        }
      }
    }
  }
}
