.endpoint-policies-page {
  &.crud-form {
    min-height: 256px;
  }

  .show-more-btn {
    padding: 0.15em 0;

    .show-more-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .bulk-actions-info-message {
    .icon {
      margin: 0;
    }
  }

  .application-points-tree-grid {
    &.ui.table {
      th {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
      }

      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .application-point-title {
      .ui.accordion {
        .title {
          padding: 0.3em 0;
        }

        i.dropdown.icon.hidden {
          visibility: hidden;
        }
      }
    }

    .policy-assignment-state {
      min-height: 1.5em;

      &.ui.menu {
        .item {
          padding: 0;

          &.hidden {
            visibility: hidden;
          }

          .error-icon {
            position: absolute;
            left: -18px;
            top: 2px;
            cursor: pointer;
          }
        }
      }
    }

    .remove-visible-policy {
      margin-left: 5px;
    }

    .application-point-applied-endpoint-policies {
      .ui.label {
        padding: 0.15em 0.3em;
      }

      .template-actions-btn {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}
