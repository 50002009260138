@import (reference) '~theme/globals/site.variables';

.ui.segment.logical-device-dropdown {
  margin-bottom: 2em;

  h5.header.dividing {
    padding-top: 3px;
    font-size: 0.9em;
  }

  .ui.dropdown .item .description {
    color: @red;
  }

  &.disabled .device-summary {
    opacity: 0.5;
  }
}
