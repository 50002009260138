@import url("./const.less");

.device-profile {
  .block-top-label("Applicable Device Profiles");
  display: flex;
  align-items: center;
  min-width: @structure-block-width;
  flex: 1 1;

  label {
    .overflown-block();
    .physical-entity();  
    font-size: 0.8rem;

    &[role="button"] {
      cursor: pointer;
    }
  }
}