@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';

.probe-list-table {
  .tag-list {
    > div:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.ui.button.dropdown.add-probe-button {
  margin-right: 0;

  span .add.icon {
    margin: @iconMargin;
  }
}
