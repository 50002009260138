@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';
@import (reference) '../../styles/variables';

.graph-explorer {
  .options-label {
    background-color: transparent;
  }
  .ui.segment > .ui.grid {
    & > .column.qe-nav {
      padding-bottom: 0;
    }
    & > .column:last-child {
      padding-top: 0;
      .ui.button:not(:first-child) {
        margin-right: 0;
        margin-left: @compactHorizontalPadding;
      }
    }
  }
  .ui.grid {
    & > .column.explorer-header-edit-mode {
      padding-top: 0;
      .header {
        vertical-align: middle;
        line-height: @36px;
      }
    }
  }
  .code-editor-control .ace_editor .ace_placeholder { // stylelint-disable-line selector-class-pattern
    font-family: monospace;
    font-size: 12px;
    padding-top: 6px !important;
  }
  .graph-view-wrapper {
    height: 80vh;
  }
  .fetch-contextual-data-checkbox {
    float: right;
    margin-top: 9px;
  }
  .builder-mode {
    & > .code-editor-control {
      margin-bottom: 1rem;
      position: sticky;
      top: calc(@breadcrumbsHeight + @20px);
      z-index: 1;
    }
  }
}

.ui.modal.fullscreen.catalog-modal {
  min-height: calc(100vh - 4rem);
  .sticky-pagination-wrapper {
    display: none;
  }
}
.ui.modal.query-delete-modal {
  top: 50%;
  transform: translateY(-50%);
}
