@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';
@import (reference) '../../../../styles/variables';

div.report-filtering-container.grid {
  i.icon {
    &.expand-icon > svg {
      width: 12px;
      height: 11px;
    }

    &.collapse-icon > svg {
      width: 14px;
      height: 13px;
      transform: translateY(1px);
    }
  }

  .ui.button:hover, .ui.button:focus, .ui.button.disabled {
    & > i.icon > svg {
      path {
        fill: @white;
      }
    }
  }
}
