@import (reference) '~theme/globals/site.variables';
@import (reference) '../../styles/variables';

@secondaryHeaderColor: #8a8a8a;
@indicatorGap: @8px;
@indicatorSize: @6px;

.live-indicator {
  .block {
    padding: 5px 0;
  }

  .header {
    color: @secondaryHeaderColor;
    margin-bottom: 0.75rem;
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  hr {
    border: none;
    height: @1px;
    background-color: rgba(@white, 15%);
    margin: 10px 0;
  }

  .expanded-indicator {
    color: @white;
    font-size: @13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      margin-right: 5px;
    }
    .ui.small.label {
      margin-right: 0;
    }
  }

  .note {
    white-space: break-spaces !important;
    font-size: @12px;
  }

  .compact-indicator {
    display: flex;
    gap: @indicatorGap;
    align-items: center;
    justify-content: center;
    height: 35px;
    &.children-3 {
      gap: 0.5 * @indicatorGap;
    }
    &.children-4, &.children-5 {
      width: 2 * @indicatorSize + @indicatorGap;
      height: 2 * @indicatorSize + @indicatorGap;
      flex-wrap: wrap;
      >:nth-child(5) {
        position: relative;
        top: -1.5 * (@indicatorGap + @indicatorSize);
      }
    }
    > .indicator {
      width: @indicatorSize;
      height: @indicatorSize;
      border-radius: 50%;
      background-color: @grey;
      &.green {
        background-color: @green;
      }
      &.red {
        background-color: @red;
      }
      &.orange {
        background-color: @orange;
      }
    }
  }
}
