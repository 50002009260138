@import "../../variables.less";
@import "../../common.less";

.ct-diff {
  &.added {
    background-color: lighten(@eptAdded, 40);
  }

  &.deleted {
    background-color: lighten(@eptDeleted, 40);
  }

  &.different {
    background-color: lighten(@eptChanged, 40);
  }

  ul {
    padding-left: 1rem;
    margin: 0;
  }

  &.sixteen {
    padding-bottom: 0 !important;
    font-weight: bold;
  }

  &.eight {
    padding-top: 0.2rem !important;
    overflow-x: hidden;

    &:empty:after {
      content: '…';
    }
  }

  &.one.wide.arrow.column {
    width: 0 !important;
    padding: 0;
    margin: 0;

    div {
      position: absolute;
      top: -1rem;
      right: -7px;
      z-index: 10;
      font-weight: bold;
    }
  }
}
