@import url("./const.less");

div.rack-type-structure {
  display: flex;
  gap: 1.5rem;

  div.rack-type {
    .block-top-label("Rack Type");
    
    display: flex;
    flex: 0 0;
    min-width: @structure-block-width;
    align-items: center;


    label {
      .overflown-block();
      .logical-entity();

      &[role="button"] {
        cursor: pointer;
      }
    }
  }

  div.logical-devices {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
