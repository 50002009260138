@import (reference) '~theme/globals/site.variables';

.ui.table.config-diff {
  width: 100%;
  > tbody > tr > td {
    padding: 0 !important;
    vertical-align: top !important;
  }
}

.config-diff, .config-view {
  .navigation {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1;
  }
}

.config-view {
  overflow-y: auto;
  overflow-x: auto;
  max-width: 75vw;
  border: 1px solid @borderColor;
  > table {
    border-collapse: collapse;
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 13px;
    width: 100%;
    > tbody > tr {
      height: 20px;
      > td {
        padding: 0 3px !important;
        border: 0 !important;
        font-weight: 400 !important;
        vertical-align: top !important;
        &:first-child {
          &:before {
            content: attr(data-line-number);
            color: rgba(0, 0, 0, 0.4) !important;
          }
          width: 50px;
          background-color: @offWhite !important;
          user-select: none;
        }
      }
    }
  }
}

.config-diff-pane {
  border: 0;
  .config-diff-removed {
    background-color: darken(@redBackground, 5%);
  }
  .config-diff-added {
    background-color: darken(@greenBackground, 5%);
  }
  .config-diff-empty {
    background-color: @offWhite;
  }
}
