@import "../variables.less";

path.ct-link {
  fill: none;
  stroke-width: 2px;
  stroke: @eptLinkColor;
  marker-end: url("#ct-link-arrow-marker");

  &:not(.disabled):hover {
    cursor: pointer;
    stroke: @eptLinkHoverColor;
    marker-end: url("#ct-link-arrow-marker-hover");
  }
}
