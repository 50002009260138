@import '../DatacenterTopology/variables.less';

.topology-tooltip-popup {
  text-align: left;

  .tooltip-content {
    max-height: 300px;
    overflow: auto;
    overscroll-behavior: none;
    padding: 0;
    outline: none;

    .close-button {
      position: absolute;
      right: 0;
      top: 6px;
      opacity: 0;
    }
  }

  strong {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
    color: @textColor !important;
  }

  em {
    padding: 0 !important;
    margin: 5px 0 0 0 !important;
    font-size: 12px !important;
  }

  section {
    margin: 5px 0 0 0;
    padding: 0;

    font-style: italic;
    font-size: 0.9em;
    cursor: default;

    em {
      font-weight: bolder;
      font-style: normal;
      font-size: 120%;
      text-decoration: underline;
      padding-top: 5px;
      display: inline-block;
    }

    & > div {
      padding: 0;
      margin: 0 !important;

      b {
        &:not(:empty):after {
          content: ': ';
        }

        & + * {
          display: inline !important;

          &:empty:after {
            content: 'n/a';
          }
        }
      }
    }
  }
}
