@import (reference) '~theme/globals/site.variables';

.event-timeline {
  circle {
    fill: @blueBackground;
    stroke: @blue;
    stroke-width: 1px;
    &:hover {
      fill: darken(@blueBackground, 10%);
      stroke: darken(@blue, 10%);
    }
  }
  line {
    stroke: @blue;
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }
  .timeline-axis .visx-axis-tick > .visx-line {
    stroke-dasharray: 2px;
  }
}
