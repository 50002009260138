@font-face {
  font-family: "ApstraIcon";
  src: url("/static/media/ApstraIcon.b0ee1471.eot?#iefix") format("embedded-opentype"),
url("/static/media/ApstraIcon.692a7890.woff") format("woff"),
url("/static/media/ApstraIcon.a11e235f.woff2") format("woff2"),
url("/static/media/ApstraIcon.3910b888.ttf") format("truetype"),
url("/static/media/ApstraIcon.01df9e6d.svg#ApstraIcon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.apstra-icon:before {
  font-family: "ApstraIcon";
  display: inline-block;
  vertical-align: middle;
}

.apstra-icon-group-generator:before {
  content: "\f101";
}
.apstra-icon-group:before {
  content: "\f102";
}
.apstra-icon-resource-allocation:before {
  content: "\f103";
}
.apstra-icon-resource-generator:before {
  content: "\f104";
}
.apstra-icon-resource:before {
  content: "\f105";
}
.apstra-icon-build-configlets:before {
  content: "\f106";
}
.apstra-icon-build-device-profiles:before {
  content: "\f107";
}
.apstra-icon-build-devices:before {
  content: "\f108";
}
.apstra-icon-build-resources:before {
  content: "\f109";
}
.apstra-icon-remote-gateway:before {
  content: "\f10a";
}
.apstra-icon-active:before {
  content: "\f10b";
}
.apstra-icon-analytics:before {
  content: "\f10c";
}
.apstra-icon-anomaly:before {
  content: "\f10d";
}
.apstra-icon-arca:before {
  content: "\f10e";
}
.apstra-icon-build-errors:before {
  content: "\f10f";
}
.apstra-icon-catalog:before {
  content: "\f110";
}
.apstra-icon-commit-check:before {
  content: "\f111";
}
.apstra-icon-ct:before {
  content: "\f112";
}
.apstra-icon-dashboard:before {
  content: "\f113";
}
.apstra-icon-dashboards:before {
  content: "\f114";
}
.apstra-icon-dci:before {
  content: "\f115";
}
.apstra-icon-dynamic-row:before {
  content: "\f116";
}
.apstra-icon-edit:before {
  content: "\f117";
}
.apstra-icon-export:before {
  content: "\f118";
}
.apstra-icon-fabric-settings:before {
  content: "\f119";
}
.apstra-icon-flow-data:before {
  content: "\f11a";
}
.apstra-icon-full-nodes-diff:before {
  content: "\f11b";
}
.apstra-icon-import:before {
  content: "\f11c";
}
.apstra-icon-logical-diff:before {
  content: "\f11d";
}
.apstra-icon-physical:before {
  content: "\f11e";
}
.apstra-icon-policies:before {
  content: "\f11f";
}
.apstra-icon-probe:before {
  content: "\f120";
}
.apstra-icon-processor-category-graph:before {
  content: "\f121";
}
.apstra-icon-processor-category-processing:before {
  content: "\f122";
}
.apstra-icon-processor-category-telemetry:before {
  content: "\f123";
}
.apstra-icon-query:before {
  content: "\f124";
}
.apstra-icon-report:before {
  content: "\f125";
}
.apstra-icon-stage:before {
  content: "\f126";
}
.apstra-icon-staged:before {
  content: "\f127";
}
.apstra-icon-tasks:before {
  content: "\f128";
}
.apstra-icon-time-voyager:before {
  content: "\f129";
}
.apstra-icon-uncommitted:before {
  content: "\f12a";
}
.apstra-icon-virtual:before {
  content: "\f12b";
}
.apstra-icon-warning:before {
  content: "\f12c";
}
.apstra-icon-widget:before {
  content: "\f12d";
}
