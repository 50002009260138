@import (reference) '~theme/globals/site.variables';

.topology-vlan-bus {
  cursor: pointer;
  stroke-width: 2px;
  fill: none;
  span {
    font-size: 10px;
    line-height: 10px;
    background: @white;
    font-weight: bold;
    padding-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    opacity: 1 !important;
    stroke-width: 3px;
  }

  @colors: teal, purple, orange, blue, violet, green, pink, brown, olive, black, red;
  each(@colors, {
    &.tp-vlan-@{value} {
      stroke: @@value;
      color: @@value;
    }
  });
}

.topology-vlan-bus-group:hover {
  .topology-vlan-bus {
    opacity: 0.25;
  }
}
