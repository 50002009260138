@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.field.stage-input {
  .ui.table {
    margin: 0;
    tr:not(:first-child) td {
      border-top: @border;
    }
  }
}
