@import (reference) '~theme/globals/site.variables';

.ui.grid.login-page {
  height: 100%;
  > .cover {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("../../../images/login-bg.jpg");
    background-position: center;
    background-size: cover;
  }
  > .column {
    width: 450px;
    padding: 40px;
    background-color: @white;
    border-radius: @10px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.3);
    .logo-row {
      margin-bottom: 10px;
      .juniper-logo {
        width: 140px;
        height: 68px;
        margin: 0 auto;
        background-image: url("../../../icons/login-page-logo.svg");
        background-position: center;
        background-size: cover;
      }
    }
    .extra-label-container {
      display: flex;
      justify-content: center;
      .extra-label {
        color: @white;
        font-size: @10px;
        font-weight: @normal;
        line-height: @10px;
        letter-spacing: @1px;
        user-select: none;
        border-radius: @5px;
        padding: @3px @9px;
        background-color: darken(@grey9, 10%);
      }
    }
    .form {
      padding: 1em 0;
    }
  }
  .apstra-server-version {
    position: absolute;
    bottom: 2em;
    font-size: @10px;
    font-weight: @bold;
    color: @black;
    opacity: 0.7;
  }
}
