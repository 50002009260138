@import (reference) '~theme/globals/site.variables';

.query-node-attribute {
  width: 100%;
  display: inline-grid;
  grid-template-columns: minmax(0, 1fr) min-content;
  grid-column-gap: @10px;
  grid-row-gap: @10px;
  justify-items: center;
  align-items: flex-start;

  & > .ui.icon.button {
    margin-right: 0;
    padding-top: 1em;
    background: none;

    .icon.question {
      height: initial;
    }
  }
}

.matcher-block {
  width: 100%;
  display: inline-grid;
  grid-column-gap: @10px;
  align-items: flex-start;

  &.two {
    grid-template-columns: minmax(25%, min-content) minmax(0, 1fr);
  }
}
