.neighbors-view {
  display: flex;
}

.neighbors-view-filter {
  margin: 0 auto 0.8em;
  text-align: right;
  display: flex;
  .space {
    flex: 1;
  }
}
