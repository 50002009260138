@import '../variables.less';

div.re-button-alert {
  .warning.sign.icon {
    font-size: 15px;
    position: absolute;
    margin-top: -10px;
    margin-left: 20px;
    color: @red;
    text-shadow:
      -1px -1px 0 @white,
      1px -1px 0 @white,
      -1px 1px 0 @white,
      1px 1px 0 @white;
  }

  &.left .warning.sign.icon {
    margin-left: -25px;
  }
}
