@import "../variables.less";

div.ctrl-node-properties {
  .required.field label:after {
    content: "*";
    color: @red;
    margin-left: 2px;
  }

  ul.color-picker {
    padding: 0;
    margin: 0;

    li.ui.button {
      list-style: none;
      padding: 0;
      margin: 2px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: solid 2px transparent;
      border-radius: 8px;

      &.selected {
        border: solid 1px @white;
        outline: solid 1px @black;
      }

      &.color-null {
        border: solid 1px lighten(@black, 50%);
      }

      &.color-1 {
        background-color: @nodeRed;
      }

      &.color-2 {
        background-color: @nodeOrange;
      }

      &.color-3 {
        background-color: @nodeYellow;
      }

      &.color-4 {
        background-color: @nodeLime;
      }

      &.color-5 {
        background-color: @nodeCyan;
      }

      &.color-6 {
        background-color: @nodeBlue;
      }

      &.color-7 {
        background-color: @nodePurple;
      }
    }
  }
}
