@import (reference) '~theme/globals/site.variables';

.interface-map-container {
  overflow-x: auto;
  overflow-y: hidden;

  .port-map {
    border-collapse: collapse;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .ui.segment.port {
    background-color: @grey;
    border: 1px solid @white;
    display: inline-block;
    text-align: center;
    user-select: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 1;
    overflow: visible;
    position: relative;

    span {
      color: @white;
      font-size: 9pt;
      line-height: 2.8;
      width: 100%;
      display: inline-block;
      font-weight: 100;

      .autoneg {
        display: block;
        position: absolute;
        left: 2px;
        top: -7px;
        font-size: 0.7rem;
        color: @white !important;
      }
    }

    &.selected {
      filter: brightness(120%);

      span {
        font-weight: bold;
      }
    }

    &.grayed {
      filter: brightness(65%);
    }
  }

  .unused-interfaces {
    margin-top: 20px;
  }
}
