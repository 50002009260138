@import (reference) '~theme/globals/site.variables';

.neighbors-view {
  .link {
    stroke-dasharray: 5.5;
    stroke-width: 2px;
  }
  .link-background {
    stroke-width: 8px;
    stroke: transparent;
  }
}

div.link-tooltip {
  display: flex;
  flex-direction: row;

  & > div {
    margin: 0;

    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }
}
