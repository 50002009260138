@import "../variables.less";

@outline: fade(@white, 80%);

.device-profile-port-map-container {
  table.device-profile-port-map {
    td.port {
      span.port-id {
        margin-top: -2px;
      }

      &.selected {
        border: solid 2px @black;
        border-radius: 2px;

        span.port-id {
          color: @lightGrey;
        }
      }
    }
  }
}

div.ctrl-port-map {
  .slot-container {
    h3 {
      font-size: 1rem;
    }
    .ui.segments {
      border: none;
      box-shadow: none;
    }
  }
}