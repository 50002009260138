.report-form() {
  .datetime-field {
    .field {
      margin-bottom: 0;
    }
  }
  .duration-input.custom-range {
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr) min-content;

    & > label {
      grid-column: span 3;
    }
  }
}
