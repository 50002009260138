@import "../Node.less";

@selectedOutlineColor: @black;

.cme-aggregate {
  svg {
    margin-bottom: -10px;

    path {
      stroke: @grey;
      stroke-width: 1px;
      fill: none;
      cursor: pointer;

      &.outline {
        stroke-width: 2px;
        stroke: @white;

        &.aggregated {
          stroke-width: 4px;
        }

        &.disabled {
          stroke: none;
        }

        &.selected {
          stroke-width: 3px;
          stroke: @selectedOutlineColor;

          &.aggregated {
            stroke-width: 5px;
          }
        }

        &.error {
          stroke: @red;
        }
      }
      &:not(.outline) {
        &.deleted {
          stroke: fade(@red, 70%);
        }

        &.disabled {
          stroke-dasharray: 7px 5px;
        }

        &.aggregated:not(.disabled) {
          stroke: @teal;
          stroke-width: 3px;
          stroke-dasharray: 3px 2px;

          &.selected {
            stroke: @white;
            stroke-width: 2px;
            stroke-dasharray: 2px 2px;
          }
        }
      }

      &.same-group {
        stroke-width: 2px;
        stroke-dasharray: 2 2;
        cursor: default;
      }
    }
  }
}
