@import (reference) '~theme/globals/site.variables';

.query-property {
  width: 100%;

  .ui.form {
    margin-bottom: @15px;
    .field {
      margin-bottom: @15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
