.ui.sortable.table > thead > tr > th:not(.disabled):not(.ascending):not(.descending):not(.checkbox):after {
  display: inline-block;
  content: @sortableIcon;
}

.ui.sortable.table > thead > tr > th {
  white-space: pre-wrap;
}

.ui.table thead th.disabled:hover [data-tooltip][data-inverted] {
  color: @disabledTextColor;
}
