@import (reference) '~theme/globals/site.variables';

.webcons {
  &.simple {
    .icon-container {
      display: none !important;
    }
    .object-key-val > span > span {
      pointer-events: none !important;
    }
  }
  .simple-view-checkbox {
    margin-bottom: 16px;
  }
  .link {
    cursor: pointer !important;
    color: @linkColor !important;
    &:hover {
      text-decoration: underline;
    }
  }
  .buttons-container {
    margin-top: 16px;
    display: flex;
    gap: 10px;
    .path-input {
      flex-grow: 1;
    }
  }
  .data {
    &.directory {
      .object-content .object-content .object-key-val:nth-of-type(2) .string-value {
        .link();
      }
    }
    &.partitions {
      .string-value {
        .link();
      }
    }
  }
}
