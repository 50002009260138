@import '../DatacenterTopology/variables.less';

.link-tooltip-label {
  font-weight: bold;
  text-align: center;
}

.tooltip-group .tooltip-item .tooltip-item-name:before {
  content: "- ";
}

.tp-link-tooltip {
  display: flex;
  padding-bottom: 5px !important;

  & > div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .lag-counter {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
    }

    &:not(.node) {
      flex-grow: 1;
      position: relative;

      hr {
        min-width: 50px;
        border: 1px dashed lighten(@grey, 20%);
        border-width: 1px 0;
        height: 1px;
      }
    }

    &.node {
      .node-internals {
        display: flex;
        flex-grow: 0;
        background-color: lighten(@grey, 50%);
        border-radius: 3px;
        padding: 5px 0;

        &.right {
          flex-direction: row-reverse;
          align-self: right;

          .interface i {
            border-radius: 0 3px 3px 0;
          }
        }

        &:not(.right) {
          .interface i {
            border-radius: 3px 0 0 3px;
          }
        }

        div {
          display: flex;
          flex: 0 1 30px;
          justify-content: center;
          flex-direction: column;

          &.interface-data {
            div {
              margin-left: 8px !important;
              margin-right: 8px !important;
              white-space: nowrap;
            }
          }

          &.interface {
            flex: 1 1 50px;
            padding: 0 !important;

            i {
              flex-grow: 0;
              text-align: center;
              display: block;
              background-color: lighten(@grey, 10%);
              color: @invertedTextColor;
              padding: 2px 5px;
              min-width: 20px;

              &:empty:after {
                content: 'n/a';
              }
            }
          }
        }
      }
    }
  }
}
