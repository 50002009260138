@import (reference) '~theme/globals/site.variables';

.node-card {
  .node-name {
    word-break: break-all;
  }
  .ui.statistic {
    display: block;
    margin: 1em 0 !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
    .value, .label {
      text-transform: none;
    }
    .value {
      margin-right: 0.75em;
    }
    .label {
      font-weight: normal;
      position: relative;
      top: 3px;
      margin: 0;
      text-align: left;
    }
  }
  .ui.list .item .ui.statistic {
    margin: 0 !important;
  }
  .help-tooltip-icon {
    color: @red;
    cursor: default;
    padding-left: 3px;
    font-weight: bold;
  }
}
