@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/modules/checkbox.variables';

.ui.table.data-table {
  th.checkbox, td.checkbox {
    padding: 0;
    position: relative;
    width: 3 * @checkboxSize;

    > .checkbox {
      &, & > label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      & > label {
        &:before, &:after {
          left: 50%;
          margin-left: -(@checkboxSize / 2);
          top: 50%;
          margin-top: -(@checkboxSize / 2);
        }
      }
    }
  }

  th.checkbox > .checkbox {
    & > label {
      &:before, &:after {
        margin-top: -@checkboxSize;
      }
    }

    & + small {
      display: block;
      font-size: 9px;
      padding: 0 0.5em;
      margin: 3em 0 0.5em;
    }
  }

  tr.select-all-items {
    .dotted-underline-button {
      margin-left: 3px;
    }
  }
}

table.ui {

  .added {
    background-color: @positiveBackgroundColor;
  }

  .removed {
    background-color: @negativeBackgroundColor;
  }

  .changed {
    background-color: @infoBackgroundColor;

    div.before {
      text-decoration: line-through;
      color: @grey !important;
    }
  }
}
