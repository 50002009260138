@import (reference) '~theme/globals/site.variables';

.processor-modal-card {
  border-radius: @5px;
  border: 0 solid transparent;
  color: var(--card-color);
  width: 100%;
  overflow: hidden;

  &.with-border {
    border: @2px solid var(--card-color);
  }

  .card-title {
    background-color: var(--card-color);
    color: @white;
    font-size: @12px;
    font-weight: 700;
    height: @23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-content {
    background-color: color-mix(in sRGB, var(--card-color) 20%, @white);
    padding: @20px;
    display: flex;
    gap: @10px;
    flex-direction: column;
    height: 100%;
  }

  .card-item {
    background-color: color-mix(in sRGB, var(--card-color) 10%, @white);
    border-radius: @5px;
    border: @1px solid var(--card-color);
    font-weight: 700;
    padding: @10px;
    display: flex;
    overflow: hidden;
    align-items: center;

    &.with-input {
      background-color: @white;
    }
  }
}