@import '~apstra-ui-common/styles/colors.less';
@import (reference) '~theme/globals/site.variables';
@import (reference) './ProbeGraph/ProbeGraph.less';

.probe-items {
  display: flex;
  flex-direction: column;
  gap: @40px;
  .processor-accordions {
    border: dashed 1px @borderColor;
    border-radius: @5px;
    display: flex;
    flex-direction: column;
    gap: @10px;
    padding: @20px;
    .accordion.stage {
      background-color: rgba(@stageColor, 10%) !important;
      &.has-errors {
        border-left: @3px solid @red;
        background: @errorBackgroundColor !important;
      }
      >.title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: @10px !important;
        .stage-label {
          display: inline-flex;
          gap: @8px;
          background-color: @stageColor;
          color: @white;
          font-size: @14px;
          font-weight: 500;
          padding: @6px @8px;
          .icon {
            &.apstra-icon {
              font-size: @16px;
            } 
            margin: 0;
          }
        }
      }
      .processor-label {
        align-items: center;
        background-color: @processorColor !important;
        color: @white;
        display: inline-flex;
        gap: @8px;
      }
      >.content {
        >.scrollable-content {
          display: grid;
        }
        padding: @10px;
        overflow: auto;
      }
    }
  }
}
