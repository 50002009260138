.tooltip-item {
  font-style: italic;
  font-size: 0.9em;
  .tooltip-item-name {
    font-weight: bold;
    &:after {
      content: ": ";
    }
  }
  > * {
    &:empty:after {
      content: 'n/a';
    }
  }
  span.label, div.label {
    font-size: 0.5rem !important;
    position: relative;
    top: -1px;
  }
}
