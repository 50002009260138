@import (reference) '~theme/globals/site.variables';

.logical-device-form {

  .ui.segment {
    &:first-child {
      margin-top: 15px;
    }
    &:last-child {
      margin-bottom: 15px;
    }
  }

  .ui.header {
    text-transform: uppercase;
  }

  .ui.button {
    &.red {
      cursor: default;
    }
  }

  h3 {
    margin: 0;
  }

  .field > label {
    font-weight: 600;
    margin-bottom: 5px;
    display: inline-block;
  }

  .ui.cards {
    margin: 0;
  }

  h4.ui.header.dividing {
    color: @lightTextColor;
    font-weight: normal;
    margin-bottom: 5px;
    position: relative;

    > div {
      position: absolute;
      right: 0;
      top: 0;
      color: @black;
      font-size: 14px;
      text-transform: none;
    }
  }

  .ui.card.statistic-card {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;

    .ui.statistic {
      padding: 5px;
      margin: 3px;
      cursor: pointer;

      &.total, &.readOnly { // stylelint-disable-line selector-class-pattern
        cursor: default;
      }
      &.edited, &.hovered {
        background: @darkWhite;
      }
      &:first-child {
        padding-left: 0;
      }

      > .value, > .label {
        text-transform: none;
      }
    }
  }

  .port-panel h3 {
    height: 36px;
    position: relative;

    .ui.button {
      position: absolute;
      right: -4px;
      top: 0;
    }
  }

  .port-group-form {
    margin-top: 0;

    h4.ui.header {
      text-transform: none;
      color: @textColor;
      font-weight: bold;
      margin-bottom: 1em;
    }

    .row:not(:last-child) {
      padding-bottom: 0;
    }

    .port-roles {
      .field {
        margin: 0;
        .checkbox label {
          font-weight: normal;
        }
      }
    }

    .port-speed {
      width: 100px;
    }

    .port-number {
      float: left;

      input[type=number] {
        padding: 8px;
        min-width: 112px;
      }
    }

    .rc-slider {
      margin-top: 6px;
      margin-left: 130px;
      width: 50%;
    }

    .button-container {
      padding-top: 12px;
    }

    .column > label {
      display: inline-block;
      width: 100%;
    }
  }

  .interface-map-warning {
    .accordion .content {
      padding: 0;

      ul {
        margin: 0;
        padding-left: 20px;
        list-style-type: none;
      }
    }
  }
}
