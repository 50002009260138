@import "../variables.less";

div.ctrl-endpoint-data {
  min-width: 150px;

  &:not(.aggregate) {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 7px;

    div.field {
      flex: 1 1 150px;
      margin-right: 5px;
      margin-left: 5px;

      label {
        display: block;
      }
    }
  }

  &.aggregate {
    margin-top: 10px;
  }

  .ui.fluid.multiple.endpoint-tags.dropdown > a.ui.label {
    height: 1.5rem;
  }
}
