@import "../../variables.less";

.ct-parameters-diff-title {
  div {
    width: 100%;
    display: flex;

    & > i {
      flex: 0 0 16px;
      align-self: center;
    }
  }

  span {
    overflow-x: hidden;
  }

  &, &.active {
    &.added {
      background-color: lighten(@eptAdded, 30) !important;
    }

    &.deleted {
      background-color: lighten(@eptDeleted, 30) !important;
    }

    &.different {
      background-color: lighten(@eptChanged, 30) !important;
    }
  }
}

.ct-parameters-diff-content {
  padding-bottom: 1em !important;

  .ui.grid:empty:after {
    content: 'No changes';
    margin-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}

a.ui.button.ct-expand-advanced {
  background-color: transparent;
  text-align: left;
  padding-left: 0;
  font-weight: normal;
  color: lighten(@textColor, 30);
  font-size: 80%;
  padding-top: 0;

  & > i.icon {
    margin: 0 0 !important;

    &.down {
      margin-right: 2px !important;
    }
  }
}
