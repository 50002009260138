@import (reference) '~theme/globals/site.variables';

.ui.label.dropdown-label {
  background-color: transparent;
}

.ui.grid.config-template {
  &.ui.red.grid {
    border-top: 0.2em solid @red;
    box-shadow: 0 0 0 1px @red;
    margin-bottom: 0;
  }

  & > .row:not(.header) {
    height: calc(80vh - 25em);
  }

  .editor.column {
    padding: 1px 2px 1px 1px;
    .code-editor-control {
      border-radius: 0;
    }
  }

  .required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: @red;
  }
}

.config-template-header {
  & + .ui.red.pointing.below.basic.label {
    margin-top: 1em;
    margin-bottom: 0;
  }
  &.ui.grid > .row:last-child {
    padding-top: 0;
  }
  &.sticky {
    position: sticky;
    top: -22px;
    background: @white;
    box-shadow: @subtleShadow;
    margin: -1.5rem;
  }
}
