.topology-settings {
  margin: 0 auto 20px;
  text-align: left;
  .ui.checkbox {
    margin-right: 16px;
    label {
      font-size: 0.8em;
      font-weight: bold;
      padding-left: 2em;
    }
  }
}
