@import "../variables.less";

div.ctrl-canvas {
  width: 100%;
  min-height: 400px;
  height: calc(100vh - 150px);
  overflow: auto;
  border: 1px solid @borderColor;
  border-radius: @defaultBorderRadius;
  position: relative;

  &.fullscreen {
    height: 100%;
    background: @white;
  }

  svg.cabling-map-editor-canvas {
    user-select: none;
    .background-grid {
      fill: @gridDotColor;
    }
    &.zoom-transition > g {
      transition: transform 200ms linear;
    }
    &.pannable {
      cursor: move;
    }
    &.search-transition > g {
      transition: transform 1000ms ease-out;
    }
  }
}
