@import (reference) '~theme/globals/site.variables';
@import (reference) '../../ProbeDetails/ProbeGraph/ProbeGraph.less';

.graph-connector {
  &.incompatible {
    display: none;
  }

  cursor: pointer;
  .marker {
    fill: @white;
    stroke: @processorColor;
    stroke-width: 2px;
  }
  .marker-text {
    display: none;
  }
  path {
    stroke: @teal;
    stroke-width: 2px;
  }
  &:hover {
    .marker {
      stroke: lighten(@processorColor, 20);
      fill: @white;
    }
    .marker-text {
      display: inline;
    }
  }
  &.button {
    .marker {
      stroke: @teal;
    }
    &:hover {
      .marker {
        stroke: lighten(@teal, 20);
      }
    }
  }
  .label {
    font-size: 10px;
    stroke: @processorColor;
  }
  &.output {
    .marker {
      stroke: @purple;
    }
    &:hover .marker {
      stroke: lighten(@purple, 20);
      fill: @white;
    }
    .label {
      stroke: @purple;
    }
  }
}
