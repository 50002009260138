.link-table-card {
  padding: 15px;
  .header {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: rgb(66, 96, 178);
  }
  .main-view {
    justify-content: space-between;
    display: flex;
    align-items: center;
    .property-label {
      font-size: 10px;
      color: rgb(127, 143, 164);
    }
    .property-value {
      font-size: 10px;
      font-weight: bold;
      color: rgb(92, 93, 93);
    }
    .node-view {
      &.align-right {
        > .label {
          text-align: right;
        }
        > .interfaces {
          order: -1;
          > .node-interface {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      &.align-left {
        > .interfaces {
          > .node-interface {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      background-color: rgb(226, 231, 238);
      color: rgb(93, 93, 93);
      font-size: 14px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.35714286rem;
      margin-bottom: 6px;
      margin-top: 6px;
      padding-bottom: 4px;
      padding-top: 4px;

      .node-label {
        width: 200px;
        margin-left: 15px;
      }
      .node-properties {
        font-size: 10px;
        margin-left: 15px;
        font-weight: normal;
        color: rgb(127, 143, 164);
      }
      .interfaces {
        width: 75px;
      }

      .node-interface {
        line-height: 30px;
        background-color: rgb(114, 125, 138);
        height: 30px;
        margin-bottom: 6px;
        margin-top: 6px;
        color: rgba(255, 255, 255, 0.9);
        font-size: 10px;
        text-align: center;
        border-radius: 0.35714286rem;
        cursor: default;
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 2px;
        }
      }
    }
    .link-view {
      display: grid;
      grid-template-columns: 0.5fr 1fr 1.5fr;
      grid-template-rows: 1fr 1fr 10px 1fr 1fr;
      width: 100%;
      .link-label {
        grid-column: 2;
      }
      .link-value {
        grid-column: 3;
      }
      .link-line {
        height: 1px;
        border-style: dashed;
        border-width: 1.5px;
        align-self: center;
        width: 100%;
        grid-row: 3;
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
  }
}
