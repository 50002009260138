@import (reference) '~theme/globals/site.variables';

.device-profile-ports-form {
  .dropdown[role=listbox] {
    margin-right: 0.5em;

    i.dropdown {
      margin-left: 0.5em;
    }
  }

  .checkbox.toggle-slots {
    margin-bottom: 2em;
  }

  .add-new-slot-btn {
    margin-left: 0.5em;
  }

  .ui.accordion {
    margin-top: 0;
  }

  .slot-container + .dashed-add-button {
    margin-top: -1em;
    margin-bottom: 2em;
  }

  .slot-containers {
    margin-bottom: 0.5em;

    .content.active {
      margin-bottom: 1em;
    }

    .title.error {
      color: @red;
    }

    & + .dashed-add-button {
      margin-top: 2em;
    }

    .slot-form {
      margin-bottom: 1em;
      .slot-actions {
        display: inline;
      }
      > .field {
        margin-right: 1em;
        input[type=number] {
          width: 80px;
        }
      }
    }
  }

  > form {
    margin-bottom: 1em;

    .field > label {
      position: relative;
      top: -5px;
    }
  }

  h5 {
    position: relative;

    i {
      text-transform: none;
      font-weight: 100;
      display: inline-block;
      margin-left: 1em;
      font-size: 1rem;
    }

    .legends {
      position: absolute;
      right: 50px;
      top: 0;
      color: rgb(102, 102, 102);
    }

    button {
      position: absolute;
      right: 0;
    }
  }

  .selected-ports-form {
    .message {
      margin-top: 0.5em;
      .button {
        margin-left: 0.5em;
      }
    }
    .header {
      margin-top: 1.5em;
    }
    .label {
      cursor: pointer;
      margin-bottom: 3px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.01) !important;
      }
    }
    .add-transformation-button {
      position: relative;
      top: 25px;
    }
  }

  .slot-id-form {
    margin-bottom: 1em;
  }

  .edit-transformations-table {
    font-size: smaller;

    .field label {
      font-weight: bold;
      margin-right: 5px;
    }

    .field:last-child button {
      margin-bottom: 0.8em;
    }

    .label + i.plus {
      margin-left: 5px;
      cursor: pointer;
    }

    tfoot {
      th {
        .ui.accordion .content.active {
          padding-left: 1em;

          input[type=number] {
            width: 100px;
          }
        }
      }
    }

    .edit-interfaces-table {
      i.delete {
        cursor: pointer;

        &:hover {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .field .input {
        width: 100%;
      }

      tbody > tr > td {
        vertical-align: top;
      }
    }
  }

  .ui.red.label {
    margin-top: 1em;
  }

  .help-tooltip-icon {
    color: @red;
    cursor: default;
    position: relative;
    left: 2px;
  }
}
