@import "../variables.less";

.zoom-area {
  pointer-events: auto;
  box-sizing: content-box;
  position: absolute;
  border: 1px solid @teal;
  cursor: crosshair;
  background: @white;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  .drag-button {
    display: none;
    position: absolute;
    top: -13px;
    left: -13px;
    .button {
      background: @white !important;
      cursor: grab;
    }
  }
  &:hover .drag-button {
    display: block;
  }

  .display-area {
    position: absolute;
    background: @teal;
    opacity: 0.3;
  }

  .zoom-panel {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -30px;
    gap: 2px;
    .button {
      background: @white !important;
    }
  }
}
