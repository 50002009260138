@import (reference) '~theme/globals/site.variables';

.interface-map-form {
  h4.ui.dividing.header {
    color: @lightTextColor;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .ui.header {
    i {
      text-transform: none;
      font-weight: 100;
      display: inline-block;
      margin-left: 1em;
      font-size: 1rem;
    }
    .sub.header {
      i {
        margin-left: 0;
      }
    }
  }

  .slot-container {
    &:not(:last-child) {
      .grid .row:first-child {
        padding-bottom: 0.4em;
      }
    }
    .grid .row:not(:first-child) {
      padding-top: 0.4em;
    }
  }

  .interface-map-container {
    overflow-x: auto;
    overflow-y: hidden;

    table.interface-map-preview {
      border-collapse: collapse;
      table-layout: fixed;
      margin-bottom: 10px;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

      .port {
        width: 30px;
        min-width: 30px;
        height: 30px;
        cursor: pointer;
        line-height: 1;
        overflow: visible;
        margin: 0;
        padding: 0;
        text-align: center;
        user-select: none;
        border-radius: 0;
        border: 1px solid @white;

        span {
          color: @white;
          font-size: 9pt;
          line-height: 2.5;
          display: block;
          font-weight: 100;
        }

        &.selected {
          filter: brightness(120%);
        }
      }
    }
  }
}

.port-transformations {
  cursor: pointer;

  tr td:first-child {
    .label {
      margin-right: 1em;
    }
  }

  .ifc {
    cursor: pointer;

    &.indeterminated {
      background:
        repeating-linear-gradient(
          45deg,
          lighten(@grey, 25),
          lighten(@grey, 25) 8px,
          transparent 0,
          transparent 16px
        );
    }

    &.bulk-selection {
      padding-right: 0;

      .dropdown .dropdown.icon {
        margin: 0 1em;
      }
    }
  }
}
