.field.keys-input {
  > .ui.table {
    margin: 0;
    > tbody {
      td:first-child {
        > .fields {
          margin-bottom: 0;
        }
        > .required.field {
          margin-top: 7px;
          > label {
            font-weight: normal;
            display: inline;
            margin-right: 5px;
          }
        }
      }
      td:last-child > .icon {
        margin: 9px 0 0 0;
      }
    }
  }
  > .ui.button {
    margin-top: 5px;
  }
}
