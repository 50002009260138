@import '~apstra-ui-common/styles/colors.less';
@import (reference) '~theme/globals/site.variables';
@import (reference) './ProbeDetails/ProbeGraph/ProbeGraph.less';

.probe-processor {
  > .accordion.accordion {
    background: rgba(@processorColor, 10%) !important;
    > .content {
      padding: 0 @10px @10px !important;
    }
    &.has-errors {
      border-left: @3px solid @red;
      background: @errorBackgroundColor !important;
    }
  }
  .probe-processor-header-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: @8px;
    margin-bottom: @20px;
    .probe-contents-header {
      flex: 1;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 0;
      .processor-title {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ui.label {
        margin-top: 0;
        .iba-processor-icon {
          margin-right: 8px;
          width: 13px;
          height: 13px;
          vertical-align: top;
          color: @textColor;
        }
      }
    }
  }
  .probe-processor-header-container~.red.pointing.label {
    bottom: @20px;
    margin-top: 0;
  }
  >.accordion {
    background-color: @grey2 !important;
    clear: both;
    .title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: @10px !important;
      .item-title {
        align-items: center;
        display: flex;
        gap: @8px;
      }
      .processor-label {
        align-items: center;
        background-color: @processorColor !important;
        color: @white;
        display: inline-flex;
        gap: @8px;
        font-size: @14px;
        font-weight: 500;
        padding: @6px @8px;
        .iba-processor-icon {
          display: inline !important;
          height: @16px !important;
          width: @16px !important;
        }
      }
    }
    >.content {
      >.scrollable-content {
        display: grid;
      }
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }
  > .ui.menu {
    margin-top: -1em !important;
  }
  .python-expression {
    display: inline;
  }
}
