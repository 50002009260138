@import (reference) '~theme/globals/site.variables';

.anomaly-gauge {
  cursor: pointer;
  .gauge-arc {
    &.gauge-special-range-arc {
      fill: @red;
    }
    &.gauge-base-arc {
      fill: @green;
    }
  }
  .gauge-inner-title {
    font-size: 13px;
    text-anchor: middle;
  }
  .gauge-inner-value {
    font-size: 10px;
    font-weight: bold;
    text-anchor: middle;
    &.highlight {
      fill: @red;
    }
  }
}
