.ui.iba-dashboard-expanded.grid {
  .ui.breadcrumb + .ui.form {
    margin-left: 20px;
    display: inline-flex;
    .field {
      display: flex;
    }
  }

  & .ui.breadcrumb .ui.label .icon.box {
    margin: 0;
  }

  > .row:nth-child(n + 2) {
    padding-top: 0;
  }
}
