.ui.secondary.buttons .button,
.ui.secondary.button {
  box-shadow: 0px 0px 0px @basicBorderSize @primaryColor inset !important;
  background-color: transparent;
  color: @primaryColor;
}
.ui.secondary.buttons .button:hover,
.ui.secondary.button:hover {
  box-shadow: none !important;
  background-color: @primaryColorHover !important;
  color: @invertedTextColor !important;
}
.ui.secondary.buttons .button:focus,
.ui.secondary.button:focus {
  box-shadow: none !important;
  background-color: @primaryColorFocus !important;
  color: @invertedTextColor !important;
}
.ui.secondary.buttons .active.button,
.ui.secondary.active.button {
  box-shadow: none !important;
  background-color: @primaryColorActive !important;
  color: @invertedTextColor !important;
}
.ui.secondary.buttons .button:active,
.ui.secondary.button:active {
  box-shadow: none !important;
  background-color: @primaryColorDown !important;
  color: @invertedTextColor !important;
}

.ui.ghost.buttons .button,
.ui.ghost.button {
  background-color: @ghostButtonBackgroundColor;
  color: @ghostButtonColor;
  text-shadow: @textShadow;
  background-image: none;
}
.ui.ghost.button {
  box-shadow: none;
}
.ui.ghost.buttons .button:hover,
.ui.ghost.button:hover {
  box-shadow: 0px 0px 0px @basicBorderSize @ghostButtonBorderColor inset;
  background-color: @ghostButtonBackgroundColorHover;
  color: @ghostButtonColorHover;
}
.ui.ghost.buttons .button:focus,
.ui.ghost.button:focus {
  box-shadow: 0px 0px 0px @basicBorderSize @ghostButtonBorderColor inset;
  background-color: @ghostButtonBackgroundColorFocus;
  color: @ghostButtonColorFocus;
}
.ui.ghost.buttons .button:active,
.ui.ghost.button:active {
  box-shadow: 0px 0px 0px @basicBorderSize @ghostButtonBorderColor inset;
  background-color: @ghostButtonBackgroundColorActive;
  color: @ghostButtonColorActive;
}

.ui.buttons:not(.basic) .disabled.button,
.ui.disabled.button:not(.basic),
.ui.button:not(.basic):disabled {
  color: @disabledButtonColor !important;
  background-color: @disabledButtonBackgroundColor !important;
  box-shadow: none !important;
}

.ui.basic.buttons .disabled.button,
.ui.basic.disabled.button,
.ui.basic.button:disabled {
  color: @disabledButtonColor !important;
  border-color: @disabledButtonBackgroundColor !important;
  box-shadow: 0px 0px 0px @basicColoredBorderSize @disabledButtonBackgroundColor inset !important;
}

.ui.basic.buttons .ui.disabled.button {
  border-color: @disabledButtonBackgroundColor;
}
