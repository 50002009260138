.ui.message {
  box-shadow: none !important;
}
.ui.positive.message {
  &.icon > .icon:not(.close) {
    color: @positiveColor;
  }
}
.ui.negative.message {
  &.icon > .icon:not(.close) {
    color: @negativeColor;
  }
}
.ui.info.message {
  &.icon > .icon:not(.close) {
    color: @infoColor;
  }
}
.ui.warning.message {
  background-color: darken(@warningBackgroundColor, 3);
  &.icon > .icon:not(.close) {
    color: @warningColor;
  }
}
.ui.error.message {
  background-color: darken(@errorBackgroundColor, 3);
  &.icon > .icon:not(.close) {
    color: @errorColor;
  }
}
.ui.success.message {
  &.icon > .icon:not(.close) {
    color: @successColor;
  }
}
