@import "../variables.less";

.re-summary {
  .field {
    .required-field();
    .field-margins();

    label {
      display: block;
    }
  }

  .ui.checkbox {
    margin-top: 10px;
  }

  .ui.container {
    color: @grey;
    font-size: 0.9rem;
    font-style: italic;
    margin: 5px 0 20px 0;
    padding-left: 20px;
  }
}
