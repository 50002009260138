.virtual-statistics {
  padding: 1.5em;
  .ui.piled.segments, .ui.piled.segment {
    box-shadow: none;
    &:before, &:after {
      content: none;
    }
  }
}
.virtual-statistics-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > .ui.header {
    margin-bottom: 0;
  }
}
