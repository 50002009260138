@import '../variables.less';

div.re-peer-linker {
  display: flex;

  h6 {
    &.required:after {
      .asterisk();
    }

    font-size: 0.8rem;
    margin: 4px 0 0 0;
    white-space: nowrap;
    margin-top: 10px;

    &.none {
      visibility: hidden;
    }
  }

  & > * {
    flex: 1 1;

    &:nth-child(n + 2) {
      padding-left: 10px;
    }
  }

  div.re-peer-links {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    div.re-inputs {
      display: flex;
      flex: 1 1;

      label {
        white-space: nowrap;
      }

      .re-ports {
        flex: 0 0;
        margin-top: 5px !important;
      }

      .field {
        .required-field();

        flex: 1 1;

        &.count {
          min-width: 50px;
          margin-left: 10px;
        }
      }

      & > *:nth-child(n + 3) {
        margin-left: 10px;
      }
    }
  }

  div.re-port-channels {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    h6 {
      white-space: nowrap;
    }

    div.re-inputs {
      display: flex;

      * {
        flex: 1 1;
      }

      .field:nth-child(n + 2) {
        margin-left: 10px;
      }

      label {
        white-space: nowrap;
      }
    }
  }

  .ui.error.message.re-peer-links-errors {
    .popup-errors();
  }
}
