@import (reference) '~theme/globals/site.variables';
@import (reference) '../../styles/variables.less';

@guideWidth: @49px * 10;
@guideBackground: #F2FAFF;
@hideForeverBackground: #DEECF5;

.step-by-step-guide {
  position: fixed;
  z-index: 10;
  background-color: @guideBackground;
  color: @grey9;
  box-shadow: @floatingShadow;
  &:not(.collapsed) {
    left: calc(var(--sidebar-width) + @50px);
    bottom: @35px;
    width: @guideWidth;
    border-radius: @5px;
    padding: @20px;
    > div:not(:last-child), .content > div:not(:last-child) {
      margin-bottom: @20px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      .ui.progress {
        margin-top: @5px;
        margin-bottom: 0;
        background-color: @white;
      }
      .ui.list {
        margin-top: 0;
        .item {
          display: flex;
          align-items: center;
          padding: 0;
          i.check.circle {
            font-size: @18px;
            line-height: @18px;
            padding: 0;
            margin-right: @10px;
            &:not(.outline) {
              color: @green;
            }
          }
          a {
            color: @grey9;
            font-size: @14px;
            line-height: @17px;
            text-decoration-line: underline;
          }
          &:not(:last-child) {
            margin-bottom: @20px;
          }
          &.disabled {
            a {
              color: @grey6;
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      .hide-forever-button {
        color: @grey9;
        font-size: @14px;
        line-height: @17px;
        background-color: @hideForeverBackground;
        border-radius: @5px;
        padding: @10px;
        margin-right: 0;
        span {
          text-decoration-line: underline;
          margin-right: @12px;
          font-weight: @normal;
        }
        .icon.arrow.right {
          margin: 0;
        }
      }
    }
  }
  &.collapsed {
    height: @collapsedStepByStepGuideHeight;
    left: var(--sidebar-width);
    bottom: 0;
    width: calc(100% - var(--sidebar-width));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @10px @20px;
    .left-section {
      display: flex;
      .guide-title {
        margin-right: @20px;
      }
    }
    .hide-forever-btn {
      font-size: @14px;
      line-height: @17px;
      text-decoration-line: underline;
      margin-left: @10px;
    }
    .ui.compact.button.reset-steps-btn {
      position: absolute;
      right: @5px;
      color: @grey3;
    }
  }
  .guide-title {
    font-size: @16px;
    line-height: @19px;
    font-weight: @bold;
  }
  .ui.compact.button.ghost {
    padding: 0;
    color: @grey9;
    &:hover, &:focus, &:active {
      box-shadow: none;
      background-color: transparent;
      color: @grey10;
    }
  }
}
