@import (reference) '~theme/globals/site.variables';
@import (reference) './ProbeDetails/ProbeGraph/ProbeGraph.less';

.processor-property-graph-query-input {
  display: grid;
  gap: @10px;
  grid-template-columns: 1fr min-content;
  width: 100%;
  div > .code-editor-control {
    min-height: 100%;
  }
}

.processor-properties-category-readonly td {
  background-color: rgba(@processorColor, 10%) !important;
  border: none !important;
}

.processor-properties-category-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer !important;
  font-weight: bold;
  font-size: @15px;
}

.probe-processor {
  .property-category-title {
    font-weight: bold;
    font-size: @15px;
  }
  .processor-properties-table {
    margin: 0 0 @10px 0;
  }
}
