@import "../../variables.less";
@import "./CtSearch.less";

.condition {
  .header {
    background-color: lighten(@grey, 50%);
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    div.controls-pair {
      display: flex;
  
      &:nth-child(2) {
        flex-grow: 1;
      }
  
      .ui.dropdown.error:not(.selection) > .default.text {
        color: @negativeColor;
        
      }
      .ui.input.error > input {
        border-color: @negativeColor;
        background-color: @negativeBackgroundColor;
      }
  
      .condition-input {
        flex: 1 1;
      }

      button {
        &.ct-condition-logic-button {
          .ct-link-button();
          text-transform: uppercase;
          margin-right: 10px;
        }
    
        &.ui.icon.button {
          border: none;
          background: transparent;
          display: inline-block;
          padding-left: 0;
          padding-right: 0;
  
          &.remove-condition:not(:first-child) {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
