@import (reference) '~theme/globals/site.variables';

@hoveredColor: #FFB71B;

.neighbors-view .aggregate-link {
  fill: transparent;
  stroke: @blue;
  stroke-width: 2;
  &.hovered {
    stroke: @hoveredColor;
    cursor: pointer;
  }
}
