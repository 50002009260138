@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/form.variables';

.ui.form {
  > .range-control {
    .fields {
      margin: 0 -(@gutterWidth / 4) 1em;

      > .wide.field {
        padding-left: @gutterWidth / 4;
        padding-right: @gutterWidth / 4;
      }

      > .field.no-input-field {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
