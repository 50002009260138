@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';
@import (reference) '../../../styles/variables';

div.iba-dashboard-list {
  div.dashboard-management {
    padding: 0 0 1rem 0;
    text-align: right;

    *:last-child {
      margin-right: 0;
    }

    .add-dashboard-icon {
      margin: @iconMargin;
    }
  }

  .anomalies-count-label {
    text-align: center;
    text-transform: uppercase;
    min-width: 15 * @10px;
    margin-left: @20px;
    padding: @5px;
  }
  .iba-dashboard-expanded-container {
    scroll-margin-top: @breadcrumbsHeight + @headerNavigationHeight;
  }
}

div.anomalies-count {
  display: inline-flex;

  & > div {    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 @4px @10px;

    .anomalies-count-label {
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
      margin-left: @10px;
      padding: @5px;
      cursor: pointer;
      user-select: none;
    }
  }
}
