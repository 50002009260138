@import (reference) '~theme/globals/site.variables';

.headroom-popup.popup {
  position: absolute;
  z-index: 999;
  pointer-events: none;
  max-width: 500px;
  text-align: center;
}

.headroom-popup-properties {
  text-align: left;
  .header {
    text-align: center;
    font-weight: bold;
  }
  .description {
    text-align: center;
  }
  .property-name {
    font-weight: bold;
    color: @blue;
  }
  .property-value {
    float: right;
    margin-left: 0.5rem;
  }
}
