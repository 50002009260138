@import (reference) '~theme/globals/site.variables';

.iba-bullet-chart {
  .iba-bullet-axis {
    text {
      text-anchor: middle;
      font-size: 10px;
      fill: @black;
    }
    line {
      stroke: @grey;
      stroke-width: 1px;
    }
  }
  .iba-bullet-chart-value {
    fill: @black;
    opacity: 0.6;
    text {
      text-anchor: start;
      font-size: 12px;
      font-weight: bold;
      fill: @black;
      dominant-baseline: central;
    }
    &.align-right {
      text {
        text-anchor: end;
        fill: @white;
      }
    }
  }
}
