@import (reference) '~theme/globals/site.variables';

.resource-usage {
  min-width: 150px;
  max-width: 300px;

  .resource-async-status .ui.label {
    width: 100%;
  }

  .progress {
    position: relative;
    max-width: 100%;
    background: @disabledInputBackgroundColor;
    border-radius: @defaultBorderRadius;

    > .bar {
      line-height: 1;
      position: relative;
      width: 0;
      min-width: 2.9em;
      border-radius: @defaultBorderRadius;
      transition: width 0.1s ease, background-color 0.1s ease;
      height: @24px;
      background-color: @grey;

      > .count {
        white-space: nowrap;
        position: absolute;
        font-size: @10px;
        top: 50%;
        right: 0.5em;
        color: @white;
        margin-top: -0.5em;
        font-weight: bold;
      }
    }
  }

  .info {
    margin-bottom: 0.5em;
  }
}

.resource-async-status .ui.label {
  text-align: center;
  width: 15em;
}
