@import "../../variables.less";

div.ct-parameters-diff {
  div.search.selection {
    width: 100%;
  }

  div.tags {
    margin-bottom: 0.75em;
  }
}
