@import (reference) 'apstra-ui-common/src/brandPalette.less';
@import (reference) '~theme/globals/site.variables';
@import (reference) '../../styles/variables.less';

.explanation-chart {
  .chart {
    margin-bottom: @20px;
    .visx-line {
      stroke-width: 1px;
      each(@brandPalette, .(@color, @name) {
        &.@{name} {
          stroke: @color;
        }
      });
    }
    text {
      tspan {
        font-size: @11px;
        fill: @white;
        font-weight: bold;
      }
    }
    .vn {
      each(@brandPalette, .(@color, @name) {
        &.@{name} {
          fill: @color;
        }
      });
    }
    .node {
      fill: @blue;
    }
  }
}
