@import (reference) '~theme/globals/site.variables';

.multi-value-map-input {
  .field > label.column-title {
    font-size: @12px;
  }
  > .fields > .field.no-input-field {
    padding-top: 0;
  }
}
