@import "../../variables.less";

g.ctrl-node {
  &.interactive {
    user-select: none;
    cursor: pointer;
  }

  .title {
    font-weight: bold;
  }

  .mover {
    cursor: pointer;
    fill: @grey;
  }
}
