.upload-modal {
  .dropzone {
    height: 150px;
    .upload-description {
      margin: 20px 0;
    }
    .ui.button {
      margin-top: 30px;
    }
  }
  .ui.table {
    tr td:last-child {
      width: 48px;
      height: 48px;
    }
    tr.upload-error td {
      padding-top: 0;
      border-top: none;
    }
  }
}
