.query-start-path {
  width: 100%;

  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: max-content 1fr;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}
