@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.gauge.iba-gauge {
  .gauge-arc {
    stroke-width: 1px;
    cursor: pointer;
    each(@graph-colors, {
      @colorBackground: "@{value}Background";
      &.gauge-base-arc, &.gauge-special-range-arc {
        &.graph-color-@{value} {
          fill: @@colorBackground;
          &.highlighted {
            fill: darken(@@colorBackground, 25%);
            &:hover {
              fill: darken(@@colorBackground, 35%);
            }
          }
          &:hover {
            fill: darken(@@colorBackground, 10%);
          }
        }
      }
    });
  }
  .gauge-pointer {
    stroke: @grey;
    opacity: 0.5;
  }
  .gauge-range {
    pointer-events: none;
    stroke: @black;
    stroke-width: 1px;
    stroke-dasharray: 2px;
  }
  .gauge-value-arrow {
    fill: @black;
    stroke: @black;
    pointer-events: none;
  }
  + .gauge-info-tooltip {
    top: 0;
  }
}
