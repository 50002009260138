@import (reference) '~theme/globals/site.variables';

.query-node {
  width: 100%;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-column-gap: @10px;
  grid-row-gap: @10px;
  line-height: @38px;

  .multi-items-input {
    line-height: initial;
    .no-items-message {
      line-height: @38px;
    }
  }

  .query-node-attribute ~ .ui.basic.button {
    margin-top: @10px;
  }
}
