@font-face {
  font-family: "ApstraSidebarIcon";
  src: url("/static/media/ApstraSidebarIcon.eb3fc02f.eot?#iefix") format("embedded-opentype"),
url("/static/media/ApstraSidebarIcon.c53df944.woff") format("woff"),
url("/static/media/ApstraSidebarIcon.103eb549.woff2") format("woff2"),
url("/static/media/ApstraSidebarIcon.df18ce7e.ttf") format("truetype"),
url("/static/media/ApstraSidebarIcon.70200a6c.svg#ApstraSidebarIcon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.apstra-sidebar-icon:before {
  font-family: "ApstraSidebarIcon";
  display: inline-block;
  vertical-align: middle;
}

.apstra-sidebar-analytics:before {
  content: "\f101";
}
.apstra-sidebar-blueprints:before {
  content: "\f102";
}
.apstra-sidebar-collapse-arrow:before {
  content: "\f103";
}
.apstra-sidebar-collapse-widget:before {
  content: "\f104";
}
.apstra-sidebar-collapse:before {
  content: "\f105";
}
.apstra-sidebar-design:before {
  content: "\f106";
}
.apstra-sidebar-devices:before {
  content: "\f107";
}
.apstra-sidebar-expand-arrow:before {
  content: "\f108";
}
.apstra-sidebar-expand-widget:before {
  content: "\f109";
}
.apstra-sidebar-expand:before {
  content: "\f10a";
}
.apstra-sidebar-external-systems:before {
  content: "\f10b";
}
.apstra-sidebar-favorites:before {
  content: "\f10c";
}
.apstra-sidebar-platform:before {
  content: "\f10d";
}
.apstra-sidebar-resources:before {
  content: "\f10e";
}
.apstra-sidebar-user:before {
  content: "\f10f";
}
