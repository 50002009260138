.optional-expression-input-wrapper {
  position: relative;
  &.with-label {
    .switch-mode-icon {
      top: 24px;
    }
  }
  &.switch-mode-enabled {
    padding-right: 50px;
  }
  .switch-mode-icon {
    position: absolute;
    right: 0;
  }
}
