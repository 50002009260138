@import "../variables.less";

.ct-tagged-nodes.dropdown {
  .tags {
    margin: 6px 0 0 0;

    div.label {
      border: solid 1px lighten(@grey, 40%);
      padding: 4px 6px;
    }
  }
}
