@import (reference) '~theme/globals/site.variables';

.topology-link {
  fill: none;
  cursor: pointer;
  .link-hover-area {
    stroke-width: 5px;
    stroke: transparent;
  }

  .link {
    opacity: 0.4;
  }

  .admin-down {
    stroke-dasharray: 2, 2;
    stroke: @red;
  }

  &:hover .link {
    stroke-width: 2px;
    opacity: 0.25;
  }
}
