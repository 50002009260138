@import "../variables.less";

.ctrl-link {
  stroke: @grey;
  fill: none;

  &.outline {
    stroke-width: 3;
    stroke: fade(@white, 50%) !important;
  }

  &.error {
    stroke: @red;
  }

  &.interactive {
    cursor: pointer;

    &:hover {
      stroke: @green;
      stroke-width: 3px;
    }
  }
}
