@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.ui.table.anomaly-list {
  tr td.anomalies {
    font-weight: 800;
    text-align: center;
  }
  .ui.table.very.basic {
    td {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background: @background;
    }
  }
}

div.anomalies-grouping {
  display: inline-flex;
  padding-top: @10px;
  margin-left: @15px;
}
