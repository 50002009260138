div.ct-list-input.field {
  button {
    float: right;
    margin-top: -4px;
  }

  label {
    margin-bottom: 12px !important;
  }

  & > label + div.label.pointing.above {
    margin-top: 0;
  }
}
