@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../../styles/variables';

.probe-details {
  .ui.breadcrumb .ui.label {
    .icon.box {
      margin: 0;
    }
  }
  .stage-icon {
    fill: @textColor;
    height: 13px;
    width: 13px;
    margin-right: 8px;
    vertical-align: top;
  }
  .probe-properties {
    > .fields:last-child {
      margin-bottom: 0;
    }
  }
  .empty-probe-screen .ui.button {
    margin-right: 1em;
    margin-top: 1em;
  }
  .probe-sticky {
    position: sticky !important;
    overflow-y: auto;
    height: calc(100vh - @breadcrumbsHeight - @headerNavigationHeight - @footerContainerHeight);
    padding-top: 1em;
    top: -30px;
    z-index: 2;
  }
  .probe-toggle-form {
    display: inline-block;
    margin-left: 0.5rem;
  }
  .ui.message .content > p {
    word-break: break-word;
  }
}
