@import (reference) '~theme/globals/site.variables';

.device-profile-summary {
  display: inherit;

  .ui.segment:not(.port) {
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0.3em;
    margin: 0;

    &:not(:last-child) {
      padding-bottom: 5px;
    }

    .ui.grid {
      margin: 0;
      > .row {
        padding: 0.1rem;
        > .column {
          padding: 0;
        }
      }
    }

    .port-map {
      tr {
        line-height: 1;
      }

      .ui.segment.port {
        cursor: default;
        width: 15px;
        height: 15px;
        border-radius: 0;
        border: 1px solid @white;
        padding: 0;
      }
    }
  }

  .ui.segments {
    padding: 0.1em;
    > div:not(:last-child) .ui.segment:last-child {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    }
  }
}
