@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';
@import (reference) '../../styles/variables';

.resizable-columns {
  .ui.grid {
    border-radius: @borderRadius;

    .row {
      flex-wrap: nowrap;

      &:first-child {
        border-top-left-radius: @borderRadius;
        border-top-right-radius: @borderRadius;
      }
    }

    .header {
      background: @headerBackground;
      color: @headerColor;
      font-style: @headerFontStyle;
      font-weight: @headerFontWeight;
      text-transform: @headerTextTransform;

      .column {
        align-self: center;
      }
    }

    .column.column-resizer {
      width: 12px;
      height: 100%;
      background-color: @grey3;
      cursor: col-resize;
      padding: 0;
    }
  }
}
