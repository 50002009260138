@import "../variables.less";

div.re-ports-count {
  display: flex;
  margin: 0 0 0 -2px !important;
  padding: 4px 4px;
  white-space: nowrap;

  background-color: fade(@grey, 10%);
  border-radius: 4px;

  & > .re-port {
    flex: 0 0 @portSize;

    &:nth-child(n + 2) {
      margin-left: 4px;
    }
  }

  &.error {
    background-color: lighten(@red, 40%);
  }
}
