.ui {
  .dropdown {
    .menu {
      .collector-mapping-item {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
      }
    }
  }
}
