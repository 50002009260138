@import (reference) '~theme/globals/site.variables';

@dark-red: darken(@red, 20%);

.heatmap-node {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 1px solid @black;
  opacity: 0.7;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  &:hover, .selected {
    opacity: 1;
  }

  background-color: lighten(@grey, 30%);
  &.dark-red {
    background-color: @dark-red;
    .anomaly {
      display: none;
    }
  }
  &.red { background-color: @red; }
  &.yellow { background-color: @yellow; }
  &.purple { background-color: @purple; }
  &.teal { background-color: @teal; }
  &.green { background-color: @green; }
  &.orange { background-color: @orange; }
  .anomaly:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent @dark-red transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
  }
}

.ui.definition.table tr td.heatmap-node-group-label {
  cursor: pointer;
  &:hover {
    color: lighten(@textColor, 5%);
  }
}

.ui.header.heatmap-node-group-list-label {
  cursor: pointer;
  &:hover {
    color: lighten(@textColor, 20%);
  }
}
