@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

div.single-horizontal-bar {
  padding: 0 @cellHorizontalPadding;
  height: 100%;

  div {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    background: @blueBackground;
    border-right: 2px solid @blue;
    padding: 0 6px;

    &.negative {
      background: none;
      border-right: none;
      border-left: 2px solid @blue;
      text-align: left;
    }
  }
}
