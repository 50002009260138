.metric-query {
  .ui.form &.labels {
    margin-bottom: 0;
  }
  &.form-controls {
    align-items: center;
  }
  &.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
