@import (reference) '~theme/globals/site.variables';

.first-stop {
  stop-color: lighten(@teal, 35);
}
.second-stop {
  stop-color: @teal;
}
.utilization-chart {
  .utilization-chart-bar {
    &:hover {
      fill: @teal;
    }
  }
  .utilization-axis-bottom, .utilization-axis-left {
    line {
      stroke: @grey;
    }
    text {
      font-size: 11px;
      font-weight: bold;
      fill: @inputPlaceholderColor;
      text-anchor: end;
      font-style: normal;
      font-stretch: normal;
      line-height: 3.55;
      letter-spacing: normal;
      text-align: right;
    }
  }
  .utilization-legend {
    line {
      stroke: @grey;
      stroke-width: 1px;
    }
    text {
      fill: @grey;
      font-size: 14px;
      font-family: sans-serif;
    }
  }
}
