@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.controls-block(@column: max-content) {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: @column;
  grid-column-gap: @12px;
  height: 40px;
}

.colored-blocks() {
  > .graph-container {
    padding: @cellVerticalPadding @cellHorizontalPadding;
  }

  .value-container {
    display: table;
    width: 100%;
    height: 100%;

    each(@graph-colors, {
      &.graph-color-@{value} {
        @backgroundColorVariable: "@{value}Background";
        color: @@value;
        background-color: @@backgroundColorVariable;
      }
    });

    > div {
      padding: @cellVerticalPadding @cellHorizontalPadding;
      display: table-cell;
      vertical-align: middle;

      pre {
        margin: 0;
        max-height: 500px;
        overflow: auto;
      }
    }
  }
}

.stage-options {
  .ui.fluid.container:nth-child(n + 2) {
    margin-top: 1rem;
  }
}

.item-set-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  overflow-x: auto;

  .item-set {
    height: 100%;

    .no-samples {
      font-style: italic;
    }

    .anomaly-anchor {
      padding: @7px;
      .icon {
        margin: 0;
      }
    }
  }

  // Common graphs' properties
  .graph-container {
    width: 100%;
    min-width: 300px;

    line-height: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  // Tabular views
  table.item-set {
    th.highlight, td.highlight {
      background-color: rgba(@teal, 0.1);
    }

    td {
      white-space: nowrap;

      &.value-cell {
        &.expandable {
          &:hover {
            cursor: pointer;
            background-color: @subtleTransparentBlack;
          }
          .value-container {
            each(@graph-colors, {
              &.graph-color-@{value} {
                @backgroundColorVariable: "@{value}Background";
                &:hover {
                  background-color: darken(@@backgroundColorVariable, 5%);
                }
              }
            });
          }
        }
      }

      &.value-cell, .spotlight-mode-item-value {
        padding: 0;
        .colored-blocks();
      }

      div.graph-container {
        border: none;
      }
    }

  }

  // Spotlight view
  .spotlight-mode-container {
    outline: none;
    display: inline-grid;
    padding: 0 0 1rem 0;
    grid-row-gap: 1em;

    .spotlight-mode-item {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .spotlight-mode-item-value {
        position: relative;
        flex: 1;
        margin-right: 1rem;
        border: 1px solid @solidBorderColor;
        border-radius: @defaultBorderRadius;

        .colored-blocks();

        .icon.close {
          @iconOffset: 10px;
          position: absolute;
          margin: 0;
          top: @iconOffset;
          right: @iconOffset;
        }

        // Single graph in spotlight view
        .graph-container {
          padding-right: @cellHorizontalPadding;
          border: 0 !important;
        }
      }

      // Table schema
      .ui.table.schema-table {
        flex-basis: 250px;
        margin: 0;
        width: initial;

        // Keys
        tbody tr {
          height: 30%;

          td:first-child {
            font-weight: bold;
            background-color: @subtleTransparentBlack;
          }
        }
      }
    }
  }

  // Non-spotlight view
  .data-filtering-layout {
    // Single graph
    div.graph-container {
      padding-right: @cellHorizontalPadding;
      padding-top: @cellHorizontalPadding;

      border: 1px solid @solidBorderColor;
      border-radius: @defaultBorderRadius;
    }

    // Table of graphs
    table.item-set {
      td.expandable {
        padding-top: 0;
        padding-bottom: 0;

        // div.graph-container {
        //   padding: 0;
        // }
      }
    }
    
    >.row:last-child {
      overflow-x: auto;
    }

    // Stage data controls rendered as a part of Data Filtering Layout
    .stage-data-controls {
      .controls-block(minmax(250px, max-content));

      .column-value-input.field {
        min-width: 200px;

        .ui.search.dropdown div {
          &.text {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // The baseline of an 'inline-block' is the baseline of its last line box in
            // the normal flow, unless it has either no in-flow line boxes or if its 'overflow'
            // property has a computed value other than 'visible', in which case the baseline
            // is the bottom margin edge.
            // http://www.w3.org/TR/CSS2/visudet.html#propdef-vertical-align
            display: block;
            // The font size corresponds to the em square, a concept used in typography.
            // Note that certain glyphs may bleed outside their em squares.
            // http://www.w3.org/TR/CSS21/fonts.html#font-size-props
            height: 1.07em;
          }

          &.item {
            word-break: break-word !important;
          }
        } 
      }
    }

    // Stage data aggregation controls (second extra row)
    .stage-aggregation-controls {
      .controls-block(minmax(250px, 1fr));
    }
  }
}

.options {
  .controls-block();
  .ui.checkbox {
    margin-top: 2px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.no-data {
  padding: 0;
}

.pagination-centered {
  display: flex;
  justify-content: center;
}

table tr th.standalone.stage-footer {
  border-top: none;
}

.ui.selection.dropdown.data-source {
  justify-content: flex-start;
}

.stage-placeholder-background {
  margin: @20px;
  mix-blend-mode: multiply;
}
