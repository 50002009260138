.dashboards-display-mode {
  display: inline-block;
  margin-top: 2px;

  label {
    position: absolute;
    white-space: nowrap;
    bottom: 36px;
    left: 4px;
  }
}
