@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.iba-ds-legend {
  ul {
    margin: 0;
    list-style-type: none;
    padding-left: 0;
    > li {
      margin-right: 1em;
      > div {
        position: absolute;
        margin-top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid;
        each(@graph-colors, {
          &.graph-color-@{value} {
            @backgroundColorVariable: "@{value}Background";
            border-color: @@value;
            background-color: @@backgroundColorVariable;
          }
        });
      }
      > span {
        margin-left: 20px;
      }
    }
  }
}
