.device-context {
  display: flex;
  flex-direction: column;

  .ui.input {
    min-width: 300px;
    margin-bottom: 1em;
  }

  .ui.toggle.checkbox {
    margin-bottom: 1em;
    align-self: flex-end;
    & > label {
      font-weight: normal !important;
    }
  }

  .ui.accordion .accordion {
    margin: 0;
  }

  .ui.accordion:not(.styled) .title ~ .content:not(.ui),
  .ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui) {
    padding: 0 0 0 1.5em;
  }

  .ui.accordion .title:not(.ui) {
    padding: 0;
  }

  .flattened-keys {
    &.root {
      padding-left: 1.5em;
    }
    div, pre {
      display: inline;
    }
  }
  pre.closed {
    display: inline;
  }
}
