@import (reference) 'apstra-ui-common/src/brandPalette.less';

/* stylelint-disable selector-class-pattern, font-family-no-missing-generic-family-keyword */

.ace_line:has(.ace_completion_icon_circle):after {
  font-family: Icons;
  content: '\f111';
}

each(@brandPalette, .(@color, @name) {
  .ace_line:has(.ace_completion_icon_color_@{name}):after {
    color: @color;
  }
});
