@import "../variables.less";

.node-links {
  min-width: 530px;

  .bulk-actions {
    float: left;
  }

  button.item {
    cursor: pointer;

    &.red {
      color: @red;
    }
  }

  .ui.grid.stretched.endpoint-groups {
    margin-bottom: 8px;
    font-weight: bold;

    .row {
      min-height: 88px;

      .column.wide {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.aligned {
          &.center {
            justify-content: center;
          }
          &.right {
            justify-content: right;
          }
        }
        &:not(.center) {
          font-size: 1.5rem;
        }

        div {
          display: inline-flex;
          flex: 0 1 110px;
          align-items: center;

          &.speed-limit {
            flex: 0 0 60px;
            border: solid 8px @red;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            justify-content: center;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
