@import (reference) '~theme/globals/site.variables';

.processor-examples-panel {
  display: flex;
  gap: @6px;
  white-space: nowrap;
  margin-bottom: @20px;
  width: 100%;
}

.processor-example-popup {
  max-width: none !important;
  transform: scale(0.6) !important;
}
