@import (reference) '~theme/globals/site.variables';

.node-neighbors-traffic-graph {
  .link {
    stroke: @grey;
    stroke-dasharray: none;
    &.traffic-0-20 {
      stroke: @green;
    }
    &.traffic-20-40 {
      stroke: @yellow;
    }
    &.traffic-40-60 {
      stroke: @orange;
    }
    &.traffic-60-80 {
      stroke: @red;
    }
    &.traffic-80-100 {
      stroke: darken(@red, 20%);
    }
    &.traffic-more-than-100 {
      stroke: darken(@red, 40%);
    }
  }
  .interface {
    background-color: @grey;
    &.traffic-0-20 {
      background-color: @green;
    }
    &.traffic-20-40 {
      background-color: @yellow;
    }
    &.traffic-40-60 {
      background-color: @orange;
    }
    &.traffic-60-80 {
      background-color: @red;
    }
    &.traffic-80-100 {
      background-color: darken(@red, 20%);
    }
    &.traffic-more-than-100 {
      background-color: darken(@red, 20%);
      .icon.warning {
        display: inline-block;
      }
    }
  }
}
