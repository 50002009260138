@import (reference) '~theme/globals/site.variables';
@import (reference) '~apstra-ui-common/styles/colors.less';

div.ui.stackable.grid.data-filtering-layout.anomalies-history {
  .data-table-options .data-filtering-options {
    > .ui.menu:not(:has(+ .plain-input-search-box.plain)) {
      a:first-child {
        display: none;
      }
    }
    > .ui.menu:has(+ .plain-input-search-box.plain) {
      &:first-child {
        display: none;
      }
    }
    > .extra-content {
      display: flex;
      align-items: center;
      .extra-controls {
        display: flex;
        align-items: center;
        > div {
          margin-right: @20px;
        }
      }
      .extra-info {
        display: flex;
        justify-content: flex-end;
      }
    }
    > .plain-input-search-box.plain + .extra-content {
      .extra-controls, .extra-info {
        flex-grow: 1;
        flex-shrink: 0;
      }
    }
    > .ui.tiny.compact.menu + .extra-content {
      flex: 1;
      .extra-info {
        flex: 1;
      }
    }
    div.ui.label.persisted-label-trigger {
      display: inline-flex;
      .stage-icon {
        fill: @grey9;
        height: 13px;
        width: 13px;
        margin-right: 8px;
        vertical-align: top;
      }
      .detail {
        flex: 1;
      }
    }
  }
  .reset-selection-button {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    i.icon.small.undo:before {
      margin-left: @5px;
    }
  }
  .anomalies-history-chart-container {
    margin-top: @30px;
  }
}

div.ui.popup.transition.visible.persisted-label-popup {
  div.popup-sections {
    display: flex;
    gap: @25px;
    div.popup-section {
      ul {
        margin: 0;
        padding: @5px;
        list-style-position: inside;
        li:not(:last-child) {
          margin-bottom: @5px;
        }
      }
    }
  }
}
