@import (reference) '~theme/globals/site.variables';

.interface-setting-label {
  .help-tooltip-icon {
    color: @red;
    cursor: default;
  }
}
.ui.accordion.ifc-setting-accordion {
  .title {
    padding: 0;
  }
}
