@import '../../cablingMapEditor/variables.less';
@import '../variables.less';

div.re-node-properties {
  min-width: 150px;
  flex-grow: 1;

  h6 {
    &.required:after {
      .asterisk();
    }

    font-size: 0.8rem;
    margin: 4px 0 0 0;
    white-space: nowrap;
    margin-top: 10px;
  }

  .field {
    .field-margins();
    .required-field();
  }

  .ui.checkbox {
    display: block;
  }

  div.spine-links {
    & > div {
      display: flex;

      .field {
        flex: 1 1;
        margin-top: -2px;
      }
    }

    .count {
      margin-left: 10px;
    }
  }

  div.ui.error.message {
    .popup-errors();
  }

  div.redundancies {
    .ui.radio.checkbox {
      display: inline-block;

      &:nth-child(n + 3) {
        margin-left: 8px;
      }
    }
  }

  div.re-port-channel-range {
    display: flex;
    margin-left: -10px;

    & > * {
      flex: 1 1;
      margin-left: 10px;
    }
  }

  .ui.fluid.multiple.search.selection.dropdown.re-node-tags > .label {
    height: inherit;
  }
}
