@import "../variables.less";

@layerColors:
  dark-red darken(@red, 20%),
  red @red,
  green @green,
  orange @orange,
  yellow @yellow,
  teal @teal,
  purple @purple,
  blue @blue;

@nodeColors: @nodeRed, @nodeOrange, @nodeYellow, @nodeLime, @nodeCyan, @nodeBlue, @nodePurple;

.ctrl-node {
  &.highlighted > g, & > g:focus {
    outline: solid 3px @blue;
    border-radius: 4px;
  }

  &.clickable {
    &.readonly {
      cursor: pointer;
    }
    &:not(.readonly) {
      cursor: move;
    }
  }

  &.faded-out {
    opacity: 0.2;
  }

  .container {
    width: @nodeWidth;
    height: @nodeHeight;
    fill: @nodeDefaultBackground;
    stroke: @nodeDefaultBackground;
    stroke-width: 3px;
  }

  &.external .container {
    fill: @gridBackground !important;
    stroke: @nodeDefaultBackground;
  }

  each(@nodeColors, {
    &.color-@{index} .container {
      fill: @value;
      stroke: @value;
    }
  });

  &:not(.external) {
    &.color-6, &.color-7 {
      text tspan {
        fill: @white;
      }
    }
  }

  each(@layerColors, .(@nameColorPair) {
    @name: extract(@nameColorPair, 1);
    @color: extract(@nameColorPair, 2);
    &.color-@{name} {
      .container {
        fill: @color;
        stroke: @color;
      }
      &:not(.external) .title {
        fill: if(lightness(@color) < 50, @white, @black);
        &.fallback-title {
          opacity: 0.5;
        }
      }
    }
  });

  .title.fallback-title {
    opacity: 0.5;
  }

  .selection {
    width: calc(@nodeWidth + 8px);
    height: calc(@nodeHeight + 8px);
    fill: fade(@gridBackground, 70%);
    stroke: transparent;
    stroke-width: 0;
  }

  &.selected .selection {
    stroke: rgb(207, 114, 85) !important;
    stroke-dasharray: 8px 4px;
    stroke-width: 2px;
  }

  .invalid-icon {
    transform: translate(0, -16px) scale(0.007) matrix(1, 0, 0, -1, 7.5932254, 1333.7966);
    fill: @red;
    &:hover {
      fill: @red;
    }
  }

  circle.system {
    fill: @green;
    stroke: @black;
  }
}

.node-color, .node-remove, .node-settings, .invalid-icon {
  fill: fade(@black, 10);
  cursor: pointer;
  stroke: @white;
  stroke-width: 2;
  paint-order: stroke;
  font-family: @iconsFontFamily !important;

  &:hover {
    fill: @black;
  }
}

.node-color {
  transform: translate(@nodeWidth - 48px, -6px);
}

.node-settings {
  transform: translate(@nodeWidth - 30px, -6px);
}

.node-remove {
  transform: translate(@nodeWidth - 12px, -6px);
}

.ctrl-node-errors {
  b {
    color: @yellow;
  }
}
