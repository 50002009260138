.ui.accordion.report-query-box {
  width: 800px;
  outline: none;
  margin: 0;
  padding: 0.25em 0.5em;
  .ui.form {
    margin: 1em;
    &:focus {
      outline: none;
    }
    > .row {
      padding-bottom: 1rem;
    }
  }
  > .title {
    padding: 0.5em 0.25em;
    > .icon {
      width: 1em;
    }
  }
  .content {
    padding: 0;
  }
}
