div.cme-legend .ui.message {
  &.info {
    .ui.stretched.grid {
      margin-top: 0;
      flex-grow: 1;
    }

    .svg-sample {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 15px;
    }

    .item {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        margin-top: 2px;
      }

      &.fixed svg {
        flex-shrink: 0;
      }
    }
  }

  &.warning {
    margin-bottom: 15px;

    ul {
      padding-left: 15px;
      margin-bottom: 0;
    }
  }
}
