@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';
@import (reference) '~theme/elements/button.variables';
@import (reference) '../../styles/variables';

@row-gap: @20px;

.ui.form {
  .condition-values {
    display: grid;
    grid-row-gap: @row-gap;
    margin-top: @10px;
    > .fields {
      margin: 0;
      display: inline-grid;
      grid-template-columns: minmax(0, 1fr) min-content;
      grid-column-gap: @10px;

      > .field.no-input-field {
        padding: 0 0.5em;
        line-height: @30px;
        i {
          font-size: @tiny;
          margin-right: 2.5px;
        }
      }
    }
    > .ui.tiny.button {
      margin-left: 0;
      margin-top: -@row-gap / 2;
    }
  }
}

.condition-divider {
  @bottom: -@row-gap / 2;
  // stylelint-disable-next-line selector-class-pattern
  &._or {
    > .fields:after {
      content: 'OR';
    }
  }
  // stylelint-disable-next-line selector-class-pattern
  &._and {
    > .fields:after {
      content: 'AND';
    }
  }
  > .fields {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      border-bottom: @border;
      position: absolute;
      bottom: @bottom;
      left: 0;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: @bottom;
      left: 50%;
      transform: translate(-50%, 50%);
      padding: 0 8px;
      font-size: @tiny;
      color: @grey6;
      line-height: 1;
    }
  }
}
.fields:last-of-type {
  &:before {
    border: 0;
  }
  &:after {
    content: '' !important;
  }
}
