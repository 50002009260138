.build-tab-groups {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content minmax(0, 1fr);
  align-items: start;
  & > .label {
    margin-right: -5px;
  }
  & > .title {
    align-self: center;
  }
}
