@import (reference) '~theme/globals/site.variables';

.port-map.interface-policies-port-map {
  @portSize: 22px;
  @borderSize: 1px;

  td {
    border: @borderSize solid @borderColor !important;
    min-width: @portSize;
    height: @portSize;
    cursor: default;
    color: @textColor;
    font-weight: 200;
    text-align: center;
    font-size: 0.6rem;
    user-select: none;
    padding: 0 !important;

    &.endpoint {
      .icon {
        position: relative;
        left: 2px;
        color: lighten(@textColor, 30%);
      }

      &:not(.disabled) {
        cursor: pointer;
      }

      .endpoint-color(@color, @lightening: 40%) {
        border: @borderSize solid @color !important;
        background: lighten(@color, @lightening);
        &.hovered, &.selected:not(.disabled) {
          background: @color;
          color: @white;
        }
        &.hovered .icon {
          color: @white;
        }
      }

      &.teal {
        .endpoint-color(@teal);
      }

      &.violet {
        .endpoint-color(@violet, 20%);
      }

      &.grey {
        .endpoint-color(@grey, 45%);
      }
    }
  }
}
