@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.graph-explorer {
  .graph-view-wrapper {
    each(@brandPalette, .(@color, @name) {
      .brand-palette-node-@{name} {
        &.full-blueprint:not(.query-result):not(.trace-result):not(.trace):not(.filter-match) {
          fill: fadeout(@color, 80%);
        }
        fill: @color;
      }
      .brand-palette-label-@{name} {
        border: 1px solid @color;
        background: @color;
      }
    });
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    .graph-view {
      width: 100%;
    }
    .legend {
      overflow-y: scroll;
      width: 25%;
      margin: 0;
      .button {
        margin-bottom: 10px;
      }
      .item {
        .type-label {
          cursor: pointer;
          position: relative;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
          color: @white;
          font-weight: normal;
          &.highlighted {
            font-weight: bold;
          }
          &.unchecked {
            background: transparent;
            color: @black;
          }
        }
        .custom-color-icon {
          position: absolute;
          top: 7px;
          right: 22px;
        }
        .type-count-label {
          position: absolute;
          background-color: fadeout(@white, 30%);
          top: 4px;
          right: 4px;
        }
      }
    }
    .trace-panel {
      position: absolute;
      left: 0;
      margin: 0;
      width: (80% - 1rem);
      .label {
        width: 100%;
      }
    }
  }
}
