@import '../variables.less';

div.rt-actions {
  display: flex;

  div.extra-actions {
    flex: 1 1;
    text-align: center;

    & > div.ui.buttons {
      &:nth-child(n + 2) {
        margin-left: 10px !important;
      }

      & > button {
        border: none;
        outline: none;
      }
    }
  }
}

div.ui.error.message.rt-global-error {
  position: absolute;
  width: calc(100% - 75px);
  bottom: 80px;
  margin: 0 20px;
  text-align: left;

  pre {
    margin: 0;
    white-space: break-spaces;
  }

  p {
    margin-top: 10px;
  }
}
