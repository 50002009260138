@import '../variables.less';

path.re-links-group {
  stroke: @blue;
  stroke-width: 1px;
  fill: none;

  &.interactive {
    cursor: pointer;

    &.outline:hover {
      stroke: @blue;
    }
  }

  &.draft {
    stroke-dasharray: 5px 1px;
  }

  each(range(5), {
    &.size-@{value} {
      stroke-width: (@value);

      &.selected {
        stroke: @black;
      }

      &.outline {
        stroke: fade(@white, 50%);
        stroke-width: (@value + 2);

        &.selected {
          stroke-width: 10px;
          stroke: fade(@green, 60%);
        }
      }
    }
  });
}
