@import (reference) '~theme/globals/site.variables';

.device-profile-page {
  .ui.mini.label {
    text-transform: uppercase;
  }

  .ui.menu {
    cursor: pointer;
  }

  .ui.checkbox {
    margin-top: 10px;
  }

  h4.header.styled {
    color: @lightTextColor;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;

    i {
      text-transform: none;
      font-weight: 100;
      display: inline-block;
      margin-left: 1em;
      font-size: 1rem;
    }

    .legends {
      float: right;
      font-size: 1rem;
    }
  }

  .toolbar {
    text-align: right;
    margin-bottom: 15px;

    .group-by-dropdown {
      margin-right: 20px;
    }
  }

  table.definition {
    margin-bottom: 50px;
  }

  .ui.table.device-profile-port-table {
    margin-top: 15px;
  }

  .toolbar + .ui.segments {
    border: none;
    box-shadow: none;
  }

  .slot-container:not(:last-child) {
    padding-bottom: 30px;
  }

  .interface-statistic {
    .ui.card {
      padding: 20px 30px 10px;
      margin: 0;
      border: none;
      box-shadow: none;
      width: auto;

      .ui.statistic .value {
        text-transform: none;
        cursor: default;
        font-size: 1.2rem !important;
      }
    }
  }

  .port-map-container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .port-details {
    margin-top: 1em;

    table {
      margin-bottom: 0;
    }

    .ui.label {
      margin: 0 3px 3px 0;
      cursor: default;

      &.grey {
        opacity: 0.5;
      }
    }

    .ui.checkbox {
      margin: 0;
    }
  }
}

.imap-clone-view {

  .imap-preview {
    font-size: 0.8em;
    font-weight: bold;
  }

  h4.header {
    i {
      text-transform: none;
      font-weight: 200;
      display: inline-block;
      font-size: 1rem;
    }

    &:not(.sub) > i {
      margin-left: 1em;
    }
  }
}
