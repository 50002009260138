@import (reference) '~theme/globals/site.variables';

.system-port-map-view {
  .slots-list {
    .slot {
      .slot-title {
        font-weight: bold;
      }

      &.with-label {
        .panels-list {
          padding-left: 5px;
        }
      }

      .panels-list {
        .panel {
          .panel-title {
            font-weight: bold;
          }

          &.with-label {
            .panel-content {
              padding-left: 5px;
            }
          }

          .panel-content {
            .ports-map {
              @portSize: 22px;
              @borderSize: 1px;

              .port {
                border: @borderSize solid @borderColor !important;
                min-width: @portSize;
                height: @portSize;
                cursor: default;
                color: @textColor;
                font-weight: 200;
                text-align: center;
                font-size: 0.6rem;
                user-select: none;
                padding: 0 !important;

                &.selected {
                  font-weight: bold;
                }

                &:not(.disabled) {
                  cursor: pointer;
                }

                .port-color(@color, @lightening: 40%) {
                  border: @borderSize solid @color !important;
                  background: lighten(@color, @lightening);
                  &.hovered, &.selected:not(.disabled) {
                    background: @color;
                    color: @white;
                  }
                  &.hovered .icon {
                    color: @white;
                  }
                }

                &.teal {
                  .port-color(@teal);
                }

                &.violet {
                  .port-color(@violet, 20%);
                }

                &.grey {
                  .port-color(@grey, 45%);
                }

                &.blue {
                  .port-color(@blue, 20%);
                }
              }
            }

            .port-details {
              .interface {
                border-width: 1px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
