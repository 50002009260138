@import "../variables.less";

div.ct-canvas {
  flex-grow: 1;
  text-align: center;
  width: 100%;
  overflow: auto;

  &.intro {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.info.message {
      text-align: left;
      flex-grow: 1;
  
      button {
        width: 140px;
        margin-right: 15px;
      }
    }
  }

  svg#ct-canvas, svg#ct-canvas-readonly {
    text {
      font-family: @pageFont;
    }

    // Definitions
    marker {
      &#ct-link-arrow-marker use {
        fill: @eptLinkColor;
      }

      &#ct-link-arrow-marker-hover use {
        fill: @eptLinkHoverColor;
      }
    }
    pattern {
      &#disconnected {
        rect {
          fill: @eptDisconnectedLight;
        }
        line {
          stroke: @eptDisconnectedDark;
        }
      }
      &#incomplete {
        rect {
          fill: @eptIncompleteLight;
        }
        line {
          stroke: @eptIncompleteDark;
        }
      }
    }
  }
}
