@import (reference) '~theme/globals/site.variables';

.csv-preview {
  padding-top: 1em;

  .ui.table td.negative {
    color: @red !important;
    cursor: default;
  }

  .overflow-container {
    overflow: scroll hidden;
  }

  .ui.stackable.grid.data-filtering-layout {
    .sticky-pagination-wrapper {
      display: none;
    }
  }
}
