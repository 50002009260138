@import (reference) '~theme/globals/site.variables';

.blueprint-filter-chart {
  margin: 5px 10px 0;
  width: 100px;
  .chart-title {
    color: @grey;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
}
