@import (reference) '../../styles/variables';

.code-editor-control {
  // stylelint-disable-next-line selector-class-pattern
  .ace_gutter {
    background-color: @grey1;
  }
}

.code-diff {
  &.navigation {
    text-align: right;
    position: sticky;
    top: 0;
    z-index: 10;
    padding-bottom: 1em;
  }
}
