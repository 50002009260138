@import (reference) 'apstra-ui-common/src/graphColors.less';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.sunburst-chart-wrapper {
  display: flex;
  column-gap: 15px;

  .legend-glyph.sunburst-legend-1-glyph {
    fill-opacity: 1;
  }
  .legend-glyph.sunburst-legend-2-glyph {
    fill-opacity: 0.7;
  }
  .legend-glyph.sunburst-legend-3-glyph {
    fill-opacity: 0.4;
  }
  .legend-glyph.sunburst-legend-4-glyph {
    fill-opacity: 0.3;
  }
  .legend-glyph.sunburst-legend-5-glyph {
    fill-opacity: 0.2;
  }

  .sunburst-legend-1-item {
    padding-left: 0;
  }
  .sunburst-legend-2-item {
    padding-left: 10px;
  }
  .sunburst-legend-3-item {
    padding-left: 20px;
  }
  .sunburst-legend-4-item {
    padding-left: 30px;
  }
  .sunburst-legend-5-item {
    padding-left: 40px;
  }

  .sunburst-chart {
    .visx-arc {
      stroke: @white;
      stroke-width: 2;
      each(@brandPalette, .(@color, @name) {
        &.@{name} {
          fill: @color;
          &.hovered {
            fill-opacity: 1;
            fill: darken(@color, 10%);
          }
        }
      });
    }
    .sunburst-chart-background-arc {
      fill: @greyBackground;
      stroke-width: 0;
    }
    .label {
      stroke: @white;
      stroke-width: 4px;
      stroke-linejoin: round;
      stroke-opacity: 0.7;
      fill: @black;
      paint-order: stroke;
      font-size: 10px;
      pointer-events: none;
    }
  }
}
