@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.graph-explorer {
  .graph-view-three {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    canvas {
      background-color: @white;
    }
    &.hovered {
      canvas {
        cursor: pointer;
      }
    }
    > span {
      &.scene { background-color: @white; }
      &.link { background-color: rgb(0, 0, 0); }
      &.hovered-link { background-color: lighten(@blue, 35%); }
      &.highlighted-link { background-color: lighten(@grey, 35%); }
      &.filtered { background-color: rgb(0, 0, 0); }
      each(@brandPalette, .(@color, @name) {
        &.@{name} {
          background-color: @color;
        }
      });
    }

    .ui.popup {
      z-index: 1000;
      padding-top: 1.1rem;
      &:not(.pinned) {
        pointer-events: none;
      }
      .highlight {
        color: lighten(@blue, 20);
      }
      .top-buttons {
        cursor: pointer;
        position: absolute;
        top: -1px;
        right: -1px;
      }
    }
  }
}
