@import (reference) '~theme/globals/site.variables';
@import (reference) './ReportForm.less';

.report-filters {
  .ui.dropdown {
    .item {
      display: flex;
      flex-direction: column-reverse;
      padding-right: @30px !important;
      .text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-bottom: 1px;
      }
      .description {
        float: initial;
        margin: @10px 0 0 0;
        word-break: break-word;
      }
      .link {
        position: absolute;
        top: @10px;
        right: @10px;
        .icon {
          margin: 0;
        }
      }
    }
  }
  .report-form();
  .report-create-probe {
    margin-top: 1em;
  }
}

.ui.form {
  .report-filters {
    .field:last-child {
      margin-bottom: 1em;
    }
  }
}
