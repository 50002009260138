@import url("./const.less");

div.template-structure {
  display: flex;
  gap: 1.5rem;
  
  // stylelint-disable-next-line selector-class-pattern
  &.l3_collapsed > div.template {
    .block-top-label("L3 Collapsed Template");
  }

  // stylelint-disable-next-line selector-class-pattern
  &.pod_based > div.template {
    .block-top-label("Pod Based Template");
  }

  div.template {
    .block-top-label("Rack Based Template");
    
    display: flex;
    flex: 0 0;
    min-width: @structure-block-width;
    align-items: center;


    label {
      .overflown-block();
      .logical-entity();
    }
  }

  div.rack-types {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    div.rack-type-structure {
      .dashed-frame;
      padding: 0.5rem;
    }
  }

  div.rack-templates {
    .dashed-frame;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
