@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.state-line-chart.numeric-chart-layout {
  .visx-axis.timeline-axis.axis-left {
    display: none;
  }
  .state-line-chart-line {
    shape-rendering: auto;
    pointer-events: none;
    stroke-width: @2px;
  }
  .state-line-chart-dashed-line {
    stroke-dasharray: @3px @6px;
    stroke-width: @2px;
    pointer-events: none;
  }
  each(@graph-colors, {
    .graph-color-@{value} {
      &.state-line-chart-line, &.state-line-chart-dashed-line {
        stroke: @@value;
      }
      &.state-line-chart-value-circle {
        fill: @white;
        stroke: @@value;
        stroke-width: @2px;
      }
    }
  });
}

