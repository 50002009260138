@import "../variables.less";

div.cts-parameters {
  & > .content, & > .title, & > .ct-parameters {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: -10px;
    margin-right: -10px;
  }

  & > button.title {
    &:not(.active) {
      margin-bottom: 0.5em !important;
    }

    width: calc(100% + 20px);
    text-align: left;
    border: none;
    background: transparent;
    padding: 4px 0 2px;
  }

  & > div.content {
    &.active {
      padding-bottom: 0.5em !important;
    }
  }

  div.search.selection {
    width: 100%;
  }

  div.tags {
    margin-bottom: 0.75em;
  }

  .field:last-child {
    margin-bottom: 1em !important;
  }
}
