@import '../../DatacenterTopology/variables.less';

.topology-plane-rect {
  rect {
    stroke: @groupBorderColor;
    stroke-dasharray: 3 2;
    fill: none;
  }
  .label {
    color: @textColor;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
