@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.widget-loader {
  background-color: @white;
  border-radius: @borderRadius;
  border: @borderWidth solid @borderColor;
  padding: 0 1rem 1rem 1rem;
  margin: 0.5rem 0 0 0;

  .ui.placeholder {
    animation: none;
  }
}

.iba-widget-container {
  > .column {
    padding: 0 !important;
    margin: 0 !important;

    &.iba-widget-header {
      display: flex !important;
      min-height: 50px;

      div {
        flex-grow: 0;
        align-content: center;

        &.header {
          flex-grow: 1;
          margin: 0;
        }
      }
    }
  }

  .iba-headroom-stage-data {
    text-align: center;
    border-radius: @borderRadius;
    border: @borderWidth solid @borderColor;
  }
}
