@import "../../variables.less";

.ctrl-ifc {
  &:focus {
    outline: solid 3px darken(@blue, 10%);
    border-radius: 4px;
  }

  tspan {
    font-size: 10px;
    fill: @white;
  }
  rect {
    fill: lighten(@grey, 10%);
  }
  &.external {
    rect {
      fill: lighten(@grey, 50%);
    }
    tspan {
      fill: @black;
    }
  }

  &.interactive {
    cursor: pointer;
    user-select: none;

    &:hover rect {
      fill: darken(@blue, 10%);
    }

    &.external:hover {
      rect {
        fill: lighten(@blue, 10%);
      }
    }
  }

  &.selected circle.selection {
    fill: @black;
    stroke: lighten(@green, 20%) !important;
    stroke-width: 2px;
    stroke-dasharray: none;
  }
}
