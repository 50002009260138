@import (reference) '~theme/globals/site.variables';

@brushColor: lighten(@lightGrey, 5%);
@brushBackgroundColor: lighten(@lightGrey, 10%);

.date-in-range-slider {
  text-align: center;
  padding-bottom: 18px;
  .date-slider {
    flex: 1;
    text-align: center;
    position: relative;
    top: 7px;
    z-index: 1;
    overflow: hidden;
    padding: 0;
    .rc-slider {
      height: 16px;
      &.no-dots {
        .rc-slider-dot {
          &:not(:first-child):not(:last-child) {
            display: none;
          }
        }
      }
      &.empty {
        .rc-slider-step {
          cursor: default;
        }
        .rc-slider-track {
          display: none;
        }
      }
      .rc-slider-rail {
        background: @lightGrey;
      }
      .rc-slider-dot {
        width: 0;
        border: 0;
        border-right: 1px dashed darken(@lightGrey, 5);
        margin: 0;
        height: 7px;
        bottom: 4px;
        &:last-child {
          margin-left: -1px;
        }
      }
    }
    .intervals {
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 6px;
      pointer-events: none;
      .indicator {
        position: absolute;
        height: 1px;
        background: @teal;
      }
    }
  }
  .selected-date-text {
    font-size: 12px;
    font-weight: bold;
    height: 20px;
  }
  .aggregation-input {
    margin-right: 20px;
  }
  .period-slider {
    top: -5px;
  }
  .period-slider-controls {
    .ui.icon.button {
      padding: 5px;
    }
  }
  .brush {
    border-bottom: 50px solid @brushColor;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    transform: rotate(180deg);
    z-index: 0;
    position: relative;
    height: 50px;
    background: @brushBackgroundColor;
  }
  .rc-slider {
    border-radius: 0;
    .rc-slider-track {
      background-color: darken(@lightGrey, 5);
    }
    .rc-slider-dot-active {
      border-color: darken(@lightGrey, 5);
      box-shadow: none;
    }
    .rc-slider-dot {
      border-radius: 0;
      width: 2px;
      margin-left: -2px;
    }
    .rc-slider-handle {
      border-radius: 3px;
      width: 2px;
      box-shadow: none;
      z-index: 1;
    }
  }
  .slider-legend {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
  }
  .rc-slider-rail {
    border-radius: 0;
  }
  .rc-slider-step {
    cursor: pointer;
  }
}
