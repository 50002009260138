.graph-processor-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .step.processor .title .iba-processor-icon {
    transform: scale(0.7);
    margin-right: 6px !important;
    padding-left: 0;
  }

  .step .title span {
    font-size: 0.8em;
  }

  .step {
    padding: 0 6px 0 10px !important;
    &.processor {
      padding-left: 6px !important;
    }
  }

  .close-button {
    position: absolute;
    top: 4px;
    right: 0;
  }
}
