.re-available-ports {
  ul {
    padding: 0;
    margin: 0 0 4px 0;

    li {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 0;
    }
  }
}
