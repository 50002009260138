@import (reference) '~theme/globals/site.variables';
@import '~apstra-ui-common/styles/colors.less';

.processor-modal > .processor-description {
  display: flex !important;
  flex-direction: column;
  font-size: @14px;
  .processor-modal-card {
    --card-color: @grey4;
    color: @black;
    .card-content {
      display: block;
      height: 100%;
      overflow: auto;
    }
  }
}
