@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

@dashboardSpacer: 1rem;

.ui.equal.width.grid.iba-dashboard {
  margin: 0;
  gap: 0 @dashboardSpacer !important;

  .column.iba-dashboard-column {
    padding: 0 !important;
    transition: background-color 0.2s linear;
    background-color: @white;

    &.dragging-over {
      background-color: @darkWhite;
    }

    .iba-widget-container {
      margin: 1rem 0 0 0;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }

      .iba-widget-header + .column > .ui.error {
        margin-bottom: 0.5em;
      }
    }

    .add-widget-placeholder {
      height: 203px;
      background-color: @white;
      border-radius: @borderRadius;
      border: 1px dashed @lightGrey;
      margin: 1rem 0 0 0;
      opacity: 1;
      transition: opacity 0.1s linear;
      position: relative;

      &.hidden {
        opacity: 0;
      }

      .add-widget-placeholder-actions {
        position: absolute;
        width: 280px;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .add-widget-placeholder-background {
        margin: 0 1em;
        opacity: 0.3;
        animation: none !important;
      }
    }
  }
}
