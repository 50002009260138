@import "../variables.less";

.ctrl-aggregate-link {
  stroke: lighten(@blue, 5%);
  fill: none;

  &.interactive {
    cursor: pointer;

    &:hover path {
      stroke: @green;

      each(range(5), {
        &.bond@{value} {
          stroke-width: (@value + 2);
        }
      });
    }
  }

  &.error {
    stroke: @red;
  }

  path {
    each(range(5), {
      &.bond@{value} {
        stroke-width: (@value);

        &.outline {
          stroke-width: (@value + 2);
        }
      }
    });
  }
}
