@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/step.variables';

@processorColor: #2784cd;
@processorBackgroundColor: #e5f0fa;
@stageColor: @purple;
@stageBackgroundColor: @purpleBackground;

.probe-graph-filter {
  height: 48px;
  margin-top: -6px;
}
.probe-graph-controllers {
  text-align: right;
  .expand-button {
    position: relative;
    top: -7px;
  }
}
.probe-graph {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  .connectors-canvas {
    position: absolute;
    top: 0;
    left: 0;
    path {
      transition: stroke 0.1s ease, opacity 0.1s ease;
      fill: none;
      stroke-width: 2;
      stroke: @lightGrey;
      opacity: 1;
      &.highlighted {
        stroke: darken(@lightGrey, 40%);
      }
      &.not-highlighted {
        opacity: 0;
      }
    }
    ellipse.output-counter {
      fill: @white;
      stroke: @lightGrey;
      &.highlighted {
        stroke: darken(@lightGrey, 40%);
      }
    }
    text.output-counter {
      text-anchor: middle;
      alignment-baseline: middle;
      font-size: 8px;
      fill: @lightGrey;
      &.highlighted {
        fill: @black;
      }
    }
  }
  .ui.vertical.steps {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    line-height: 1.5em;
  }

  .ui.vertical.steps .step {
    padding: 0 10px;
    border: @stepsBorder;
    &:not(:last-child) {
      border-bottom-width: 0;
    }
    &.active:after {
      display: none;
    }
    &.processor {
      border-left: 3px solid @processorColor;
      .title {
        font-size: 1.1em;
        line-height: 26px;
        color: @black;

        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .iba-processor-icon {
          margin-right: 10px;
          opacity: 0.7;
          display: inline-block;
          vertical-align: middle;
          path {
            fill: @black;
          }
        }
      }
      &.highlighted, &:hover {
        background: lighten(@processorBackgroundColor, 2%);
        .title {
          color: darken(@processorColor, 20%);
          .iba-processor-icon path {
            fill: darken(@processorColor, 20%);
          }
        }
      }
      &.active, &.very.highlighted {
        background: darken(@processorBackgroundColor, 3%);
        .title {
          color: @processorColor;
          .iba-processor-icon path {
            fill: @processorColor;
          }
        }
      }
      &.active {
        border-color: @processorColor;
        & + .step {
          border-top-color: @processorColor;
        }
      }
    }

    &.stage, &.processor {
      .step-content {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .ui.label {
        user-select: none;
        box-shadow: none;
        background-color: transparent;
        padding-top: 4px;
      }
      .ui.floating.label {
        top: auto;
        left: auto;
        right: 3px;
        padding: 7px;
      }
    }
    &.stage {
      border-left: 3px solid @stageColor;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .title {
        display: grid;
        grid-template-columns: minmax(0, max-content) min-content;
        font-size: 0.9em;
        color: @grey;
        flex: 1 1;
        .stage-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .stage-dynamic-icon {
          padding-left: 5px;
          padding-right: 0;
        }
      }
      .stage-icon {
        height: 1.2em;
        width: 1.2em;
        top: 0.15em;
        position: relative;
        margin-right: 0.5em;
        fill: @grey;
        &:last-child {
          margin-right: 0;
        }
      }
      &.highlighted, &:hover {
        background: lighten(@stageBackgroundColor, 2%);
        .title {
          color: darken(@stageColor, 20%);
        }
        .stage-icon {
          fill: darken(@stageColor, 20%);
        }
      }
      &.active, &.very.highlighted {
        background: darken(@stageBackgroundColor, 3%);
        .icon {
          color: @white;
        }
        .title {
          color: @stageColor;
        }
        .stage-icon {
          fill: @stageColor;
        }
      }
      &.active {
        border-color: @stageColor;
        & + .step {
          border-top-color: @stageColor;
        }
      }
    }
  }

  @modifiers: warning, error;
  each(@modifiers, {
    @modifier: @value;
    @textColorVariable: "@{modifier}Color";
    @textColor: @@textColorVariable;
    @backgroundColorVariable: "@{modifier}BackgroundColor";
    @backgroundColor: @@backgroundColorVariable;
    @borderColorVariable: "@{modifier}BorderColor";
    @borderColor: @@borderColorVariable;

    .ui.vertical.steps.has-@{modifier}s {
      border-color: @borderColor;
      .ui.label {
        color: @textColor;
        .icon {
          color: @textColor !important;
        }
        .stage-icon {
          fill: @grey;
          &.issue {
            fill: @textColor;
          }
        }
        .anomaly-counter {
          line-height: 16px;
        }
      }
      .step {
        &.processor .title {
          margin-right: 15px;
        }
      }
    }

    .ui.vertical.steps.has-@{modifier}s .step, .ui.vertical.steps .step.has-@{modifier}s {
      border-left-color: @textColor;
      background: @backgroundColor;
      &.highlighted, &:hover {
        background: darken(@backgroundColor, 2%);
        .title {
          color: darken(@textColor, 20%);
          .iba-processor-icon path {
            fill: darken(@textColor, 20%);
          }
        }
      }
      &.active, &.very.highlighted {
        background: darken(@backgroundColor, 5%);
        .title {
          color: @textColor;
          .iba-processor-icon path {
            fill: @textColor;
          }
        }
      }
      &.active {
        border-color: @textColor;
        & + .step {
          border-top-color: @textColor;
        }
      }
    }
  });
}
