@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

@chartFillingOpacity: 0.2;

.line-chart {
  .line-chart-line {
    shape-rendering: auto;
    pointer-events: none;
    stroke-width: 2px;
  }
  each(@graph-colors, {
    .line-chart-line.graph-color-@{value} {
      stroke: @@value;
    }
    .line-chart-filling.graph-fill-color-@{value} {
      stroke: none;
      fill: @@value;
      fill-opacity: @chartFillingOpacity;
    }
    .line-chart-value-circle.graph-color-@{value} {
      fill: @white;
      stroke: @@value;
      stroke-width: 2px;
    }
    .line-chart-range-hover-rect {
      stroke: none;
      fill: transparent;
      pointer-events: all;
    }
    .line-chart-range-@{value} {
      stroke: @@value;
      stroke-width: 1px;
      stroke-dasharray: 2px;
      pointer-events: none;
    }
    .line-chart-range-filling.graph-fill-color-@{value} {
      fill: @@value;
      fill-opacity: @chartFillingOpacity;
      pointer-events: none;
    }
  });
}
