@import (reference) '~theme/globals/site.variables';
@import '../../../components/graphs/DatacenterTopology/variables.less';

.iba-utilization {
  .iba-utilization-nodes-rect {
    transition-property: fill;
    transition-duration: 1s;
    transition-timing-function: ease;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .iba-utilization-nodes-text {
    fill: @white;
    pointer-events: none;
    cursor: default;
    &.node-source-or-target {
      fill: @white;
      font-weight: bold;
    }
    text-anchor: middle;
  }
  .iba-utilization-links {
    transition-property: stroke;
    transition-duration: 1s;
    transition-timing-function: ease;
    opacity: 0.25;
    &:hover {
      opacity: 1;
    }
    &.iba-utilization-inactive-links {
      stroke: @grey;
    }
  }
  .iba-utilization-labels-source {
    fill: @black;
    opacity: 0.5;
    text-anchor: start;
    pointer-events: none;
  }
  .iba-utilization-labels-target {
    .iba-utilization-labels-source;
    text-anchor: end;
  }
  .iba-utilization-aggregate {
    fill: none;
    stroke: @black;
    stroke-width: 1px;
    stroke-dasharray: 2px 2px;
  }
}
