@import "../variables.less";

.ct-parameters {
  &.selected {
    border: solid 2px @eptHighlightBorderColor;
    border-radius: 4px;
    background-color: @eptHighlightColor;
  }

  .title {
    width: 100%;
    text-align: left;
    border: none;
    background: transparent;
    padding: 4px 0 2px;

    div {
      width: 100%;
      display: flex;

      & > i {
        flex: 0 0 16px;
        align-self: center;
      }
    }

    span {
      word-break: break-all;
    }

    h6 {
      margin: 2px 0 0 20px;
    }

    .ui.action.input {
      flex-grow: 1;

      input {
        width: auto;
      }
    }

    &:hover, &:focus {
      & i.pencil.icon {
        display: inline-block;
      }
    }

    .icon {
      margin-left: 4px;

      &.pencil {
        display: none;
      }

      &.exclamation.triangle {
        color: @red;
      }
    }
  }

  .content {
    .ui.multiple.dropdown > .label {
      height: auto;
      min-height: @26px;
    }

    label {
      & > i.info.circle.icon {
        pointer-events: all !important;
        position: absolute;
        margin: 0;
        padding: 0 10px 0 5px;
        font-size: 0.7em;
      }
    }

    .toggle.checkbox label {
      & > i.info.circle.icon {
        line-height: 9px;
        cursor: default;
      }
    }

    .required label {
      & > i.info.circle.icon {
        padding-left: 10px;
      }
    }

    div.not-found {
      &.field {
        opacity: 0.4;
      }
    }
  }

  div.ui.warning.message {
    ul {
      padding: 0;
      li {
        list-style: none;
      }

      &.as-list {
        padding: 0 10px;
        li {
          list-style: disc;
        }
      }
    }
  }

  a.ui.button.ct-expand-advanced {
    background-color: transparent;
    text-align: left;
    padding-left: 0;
    font-weight: normal;
    color: lighten(@textColor, 30);
    font-size: 80%;
    padding-top: 0;

    & > i.icon {
      margin: 0 0 !important;

      &.down {
        margin-right: 2px !important;
      }
    }
  }
}
