@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.named-nodes-view-error {
  height: min-content;
  margin: 0 @20px 0 0 !important;
  &.ui.message .content > p {
    word-break: normal !important;
  }
}

.named-nodes-view {
  height: max-content;
  display: grid;
  grid-template-columns: min-content min-content;
  margin-right: 0.5rem;
  .column-header {
    font-weight: bold;
    border-bottom: 1px solid @borderColor;
    padding: 0 @5px @5px;
    white-space: nowrap;
    text-align: center;
  }
  .cell {
    border-bottom: 1px solid @borderColor;
    padding: @5px @5px;
    &:nth-last-child(1),&:nth-last-child(2) {
      border-bottom: none;
    }
  }
  .node-type,.node-name {
    cursor: default;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100%;
    text-align: center;
  }
  each(@brandPalette, .(@color, @name) {
    .brand-palette-node-@{name} {
      &.node-type {
        background-color: @color !important;
      }
      &.node-name {
        color: @color !important;
        border: 1px solid @color !important;
        background-color: transparent !important;
      }
    }
  });
}
