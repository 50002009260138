@import "../variables.less";

div.ct-dict-item {
  background: @eptParametersDictBackground !important;

  i.delete {
    position: absolute;
    right: 5px;
    top: 7px;
  }

  .ct-dict-title > label {
    padding-bottom: 0.5rem;
  }
}
