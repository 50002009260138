@import (reference) '~theme/globals/site.variables';

div.iba-root {
  .ui.breadcrumb .ui.label {
    vertical-align: middle !important;
    margin-left: 0.5rem !important;
  }

  .data-table {
    .entity-name {
      font-size: 1.1em;
      .icon.box {
        margin-left: 10px;
        color: @lightGrey;
      }
    }
    .ui.label {
      white-space: nowrap;
    }
  }

  .ui.large.tabular.menu > .item {
    i.icon.book {
      font-size: @relativeLarge;
    }
    i.icon.apstra-icon {
      &.apstra-icon-dashboards,
      &.apstra-icon-anomaly,
      &.apstra-icon-probe,
      &.apstra-icon-report {
        font-size: @relativeLarge;
        &:before {
          vertical-align: baseline;
        }
      }
    }
  }
}
