@import "../../variables.less";
@import "./CtSearch.less";

.ct-primitive-selector {
  height: 300px;
  overflow-x: hidden;
  background-color: lighten(@infoBackgroundColor, 5%) !important;

  & > .content {
    overflow-y: auto;

    h5 {
      margin: 20px 0 0 0;
    }

    .item:first-child > h5 {
      margin-top: 0;
    }

    i.icon {
      margin: 0;
      cursor: pointer;
    }

    .list .item .content {
      padding: 0.2em 0;

      strong:not(:empty) {
        font-weight: normal;

        &:before {
          content: "(";
        }
        &:after {
          content: ")";
        }
      }
    }

    button {
      .ct-link-button();
    }
  }

  .header {
    padding: 1em;
    color: darken(@teal, 10);
    background-color: @infoBackgroundColor;
  }
}
