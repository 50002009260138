@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.donut-chart-wrapper {
  display: flex;
  column-gap: 15px;

  each(@brandPalette, .(@color, @name) {
    .donut-chart-arc.graph-color-@{name} {
      fill: @color;
      &.faded {
        fill: darken(@color, 10%);
        stroke: fadeout(@color, 60%);
      }
    }
    .donut-chart-arc-area.hovered .donut-chart-arc.graph-color-@{name} {
      fill: darken(@color, 20%);
      &.faded {
        fill: darken(@color, 30%);
      }
    }
  });

  .donut-chart {
    display: flex;
    position: relative;
    .children-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, calc(-50% - 2.5px));
    }
    .donut-chart-arc-area {
      cursor: pointer;
      .donut-chart-arc {
        stroke-width: 1px;
      }
      .donut-chart-arc-expander {
        stroke-width: 8px;
        stroke: transparent;
        fill: transparent;
      }
    }
    .donut-chart-background-arc {
      fill: @greyBackground;
    }
    &.pie .donut-chart-arc {
      stroke: @greyBackground;
      &.faded {
        stroke: @greyBackground;
      }
    }
    .donut-chart-arc-label {
      stroke: @white;
      stroke-width: 4px;
      stroke-linejoin: round;
      stroke-opacity: 0.7;
      fill: @black;
      paint-order: stroke;

      font-size: 10px;

      pointer-events: none;
    }
  }
}
