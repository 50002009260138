@import "../variables.less";

div.ct-builder {
  display: flex;
  flex-direction: row;
  max-height: @canvasHeight;
}

div.ct-builder-modal {
  div.ct-builder {
    min-height: @canvasHeight;
  }

  .error.message {
    h5 {
      margin-bottom: 0;
    }

    code {
      font-family: monospace;
      white-space: pre-wrap;
      display: block;
      margin-top: 0.75em;
      max-height: 150px;
      overflow-y: auto;
      background-color: fade(@black, 5);
      padding: 5px;
    }
  }
}
