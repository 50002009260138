.ui.disabled.selection.dropdown,
.ui.form .disabled.field .ui.selection.dropdown,
.ui.form .disabled.fields .ui.selection.dropdown {
  background-color: @disabledInputBackgroundColor !important;
  border-color: @disabledInputBorderColor !important;
}

.ui.disabled.dropdown:not(.selection),
.ui.form .disabled.field .ui.dropdown:not(.selection),
.ui.form .disabled.fields .ui.dropdown:not(.selection) {
  opacity: @disabledLabelOpacity;
}

.ui.multiple.dropdown > .label {
  height: @26px;
  border-radius: @defaultBorderRadius;
  background-color: @selectableItemActiveBackground;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: @subtleShadow;
  &:hover {
    background-color: @selectableItemActiveHoverBackground;
  }
}
