@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/brandPalette.less';

.chart-legend {
  font-size: 0.9em;
  display: grid;
  grid-auto-rows: min-content;

  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .legend-row {
      border-bottom: none;
      margin: 0 5px;
    }
  }

  .visx-legend-label {
    margin: 0 4px !important;
  }

  .legend-cell {
    padding: 0 5px !important;
  }

  .legend-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @borderColor;

    &:last-child {
      border: 0;
    }

    &.pointer {
      cursor: pointer;
    }

    &.hovered {
      color: @white;
      background-color: @teal;
    }
  }

  each(@brandPalette, .(@color, @name) {
    .legend-glyph.@{name} {
      fill: @color;
      stroke: @white;
      stroke-width: 1px;
    }
  });
}
