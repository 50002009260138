@import (reference) '~theme/globals/site.variables';

@nodeFontSize: 14px;
@nodeTextColor: @textColor;
@nodeBackgroundColor: #e2e7ee;
@checkBoxColor: #0096a4;

.neighbors-view .node {
  color: @nodeTextColor;
  font-size: @nodeFontSize;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: @defaultBorderRadius;

  > .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 4px;
  }

  .ui.checkbox.node-selector {
    margin-left: 5px;
    margin-top: 2px;
  }

  > .ui.checkbox label:before {
    box-shadow: none;
  }

  > .ui.checkbox input:focus ~ label:before {
    background: @nodeBackgroundColor;
  }

  > .ui.checkbox label:hover:before {
    background: @nodeBackgroundColor;
  }

  > .ui.checkbox input:checked:focus ~ label:before {
    background: @checkBoxColor;
  }

  > .ui.checkbox input:checked ~ label:hover:before {
    background: @checkBoxColor;
  }

  &.align-left {
    > .interfaces {
      > .interface {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .tp-interface {
          margin-left: 2px;
        }
      }
    }
  }

  &.align-right {
    > .label {
      text-align: right;
    }
    > .interfaces {
      order: -1;
      > .interface {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .tp-interface {
          margin-left: 1px;
        }
      }
    }
  }

  &.status-success {
    background-color: @positiveBackgroundColor;
  }

  &.status-error {
    background-color: @negativeBackgroundColor;
  }

  &.status-warning {
    background-color: @warningBackgroundColor;
  }

  &.status-info {
    background-color: @infoBackgroundColor;
  }

  &.status-not-available {
    background-color: @nodeBackgroundColor;
  }
}
