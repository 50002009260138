@import (reference) '~theme/globals/site.variables';

.make-policy-icon(@url, @size: 22px) {
  width: @size;
  height: @size;
  background-image: url(@url);
}

.icon {
  // Policy icons

  &.apstra-policies-user-attention {
    .make-policy-icon('./icons/policy-conflicts/user-attention.svg');
  }

  &.apstra-policies-auto-resolve {
    .make-policy-icon('./icons/policy-conflicts/auto-resolve.svg');
  }

  &.apstra-policies-shadow {
    .make-policy-icon('./icons/policy-conflicts/shadow.svg');
  }
}
