@import (reference) '~theme/globals/site.variables';

.histogram-chart.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > svg {
    width: 100%;
  }
  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }
  .timeline-axis {
    .visx-axis-line, .visx-axis-tick > .visx-line {
      stroke: @grey;
      opacity: 0.5;
      stroke-width: 1px;
      pointer-events: none;
    }
    .visx-axis-tick text {
      font-size: 10px;
      fill: @grey;
    }
    &.axis-bottom {
      .visx-axis-tick text {
        text-anchor: middle;
      }
    }
    &.axis-left {
      .visx-axis-tick text {
        text-anchor: end;
      }
    }
  }
  .timeline-grid line {
    stroke: @grey;
    stroke-dasharray: 2px;
    opacity: 0.2;
    pointer-events: none;
  }

  rect.bar {
    fill: @blue;
    &:hover {
      fill: darken(@blue, 10%);
    }
  }
}

.histogram-chart-layout {
  shape-rendering: crispEdges;
  .numeric-chart-layout-time-indicator {
    stroke-width: 2px;
    stroke-dasharray: 2px;
    stroke: @black;
  }
  .numeric-chart-layout-zero-line {
    stroke: @grey;
    stroke-width: 1px;
    opacity: 0.5;
    pointer-events: none;
  }
  .visx-axis-label {
    font-size: 10px;
    fill: @grey;
  }
}

.histogram-no-data {
  text-align: center;
  font-style: italic;
}
