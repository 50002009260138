.field.ept-enum-radiobutton {
  & > label {
    margin-bottom: 0.5rem !important;
  }

  .field {
    margin-bottom: 0.5rem !important;

    .radio label {
      font-weight: normal !important;
      margin-bottom: 0 !important;
    }
  }
}
