@import (reference) 'apstra-ui-common/src/brandPalette.less';
@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../styles/variables';

.circle-pack-chart.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > svg {
    width: 100%;
  }

  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }
}

.circle-pack-chart-layout {
  .node {
    each(@brandPalette, .(@color, @name) {
    &.@{name} {
      fill: @color;
      stroke: @color;
      &.parent:not(:hover) {
        fill: transparent;
      }
    }})
    &.leaf {
      fill-opacity: 0.2;
      &:hover {
        fill-opacity: 0.8;
      }
      &.unfilled {
        fill: transparent;
        &:hover {
          fill: @grey3;
        }
      }
    }
    &.parent {
      stroke-dasharray: 4;
      &:hover {
        fill-opacity: 0.1;
        stroke-dasharray: 0;
      }
    }
  }
}
