@import "../variables.less";

.ctrl-menu {
  .ui.message > .icon.info {
    color: @blue;
  }
  label {
    font-weight: bold !important;
    font-size: 1rem !important;
    color: @textColor !important;
  }
}
