div.cabling-map-editor-actions {
  padding: 2px;
  margin: 0;
  border-radius: 4px;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  & > .buttons:nth-child(n + 2) {
    margin-left: 10px;
  }

  & > button {
    display: inline-block !important;
    background-color: transparent !important;
    border: none;
    outline: none;
  }
}
