@import (reference) '~theme/globals/site.variables';

.python-expression {
  font-family: monospace;
  font-size: 12px;
  white-space: pre-line;
  &.multiline {
    display: block;
    white-space: pre-wrap;
    line-height: 15px;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 1px 0;
    .gq-indent > span:not(:first-child) {
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==") left repeat-y;
    }
  }
  .gq-function-call {
    font-weight: bold;
    color: @blue;
  }
  .gq-string {
    color: @greenDown;
  }
  .gq-infix-op, .gq-prefix-op {
    font-weight: bold;
    color: @purpleDown;
  }
  .gq-lambda {
    color: @blue;
  }
  .gq-keyword-argument {
    color: @blueDown;
  }
  .gq-node-name-highlight {
    font-weight: bold;
    background: fade(@greenBackground, 50%);
    border-radius: 2px;
  }
}
