@import "../../components/graphs/DatacenterTopology/variables.less";
@import "../../cablingMapEditor/variables.less";
@import "../variables.less";

.rack-editor-node {
  &.highlighted > g, & > g:focus {
    outline: solid 3px @blue;
    border-radius: 4px;
  }

  &.first {
    &.highlighted > g, & > g:focus {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.second {
    &.highlighted > g, & > g:focus {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.clickable {
    &.readonly {
      cursor: pointer;
    }
    &:not(.readonly) {
      cursor: move;
    }
  }

  &.faded-out {
    opacity: 0.2;
  }

  &.paired {
    .container {
      width: calc(@nodeWidth + 10px);
    }

    &.first .container {
      clip-path: inset(-4px 10px -4px -4px);
    }

    &.second .container {
      clip-path: inset(-4px -4px -4px 9px);
      transform: translate(-10px, 0);
    }
  }

  &.dimmed {
    opacity: 0.3;
  }

  &.show-ports {
    g:not(.redundancy) text {
      transform: translate(0, -12px);
      font-size: 7pt;
    }
  }

  &.role-leaf .container, &.role-leaf-pair .container {
    fill: fade(darken(@leafBackgroundColor, 10%), 60%);
  }

  &.role-access .container {
    fill: fade(darken(@accessBackgroundColor, 10%), 60%);
  }

  &.role-generic .container {
    fill: fade(darken(@genericBackgroundColor, 10%), 60%);
  }

  .container {
    width: @nodeWidth;
    height: @nodeHeight;
    fill: @nodeDefaultBackground;
    stroke: darken(@nodeDefaultBackground, 10%);
    stroke-width: 3px;
  }

  .selection {
    width: calc(@nodeWidth + 8px);
    height: calc(@nodeHeight + 8px);
    fill: fade(@gridBackground, 70%);
    stroke: transparent;
    stroke-width: 0;
  }

  &.selected .selection {
    stroke: rgb(207, 114, 85) !important;
    stroke-dasharray: 8px 4px;
    stroke-width: 2px;
  }

  .invalid-icon {
    transform: translate(0, -16px) scale(0.007) matrix(1, 0, 0, -1, 7.5932254, 1333.7966);
    fill: @red;
    &:hover {
      fill: @red;
    }
  }

  circle.system {
    fill: @green;
    stroke: @black;
  }

  g.redundancy {
    transform: translate(calc(@nodeWidth - 10px), 2px);
    rect {
      fill: @grey;
      border-radius: 2px;
    }
    tspan {
      fill: @white;
    }
  }

  g.re-svg-port {
    transform: translate(8px, 14px);
    rect {
      fill: @white;
      stroke: @black;
      border-radius: 2px;

      &.port-1G { /* stylelint-disable-line selector-class-pattern */
        fill: @orange;
      }

      &.port-10G { /* stylelint-disable-line selector-class-pattern */
        fill: @blue;
      }

      &.port-25G { /* stylelint-disable-line selector-class-pattern */
        fill: @yellow;
      }

      &.port-40G { /* stylelint-disable-line selector-class-pattern */
        fill: @violet;
      }

      &.port-100G { /* stylelint-disable-line selector-class-pattern */
        fill: @purple;
      }

      &.port-400G { /* stylelint-disable-line selector-class-pattern */
        fill: @olive;
      }
    }

    text {
      tspan {
        font-size: 12px;
      }

      &.count tspan {
        font-size: 12px;
        fill: @white;
      }
    }
  }
}

.node-color, .node-remove, .node-settings, .invalid-icon, .node-link {
  fill: fade(@black, 10);
  cursor: pointer;
  stroke: @white;
  stroke-width: 2;
  paint-order: stroke;
  font-family: @iconsFontFamily !important;

  &:hover {
    fill: @black;
  }
}

.node-link {
  transform: translate(@nodeWidth - 48px, -4px);
  font-size: 16px;
  font-weight: bold;
}

.node-settings {
  transform: translate(@nodeWidth - 30px, -6px);
}

.node-remove {
  transform: translate(@nodeWidth - 12px, -6px);
}

.re-tooltip-errors {
  .popup-errors();

  ul {
    margin: 0;
    padding-left: 8px;
    padding-right: 0;
  }

  b {
    color: @yellow;
  }
}
