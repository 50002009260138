@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/input.variables';

.build-log-filter-button {
  position: absolute;
  top: @8px;
  right: @16px;
  > .icon {
    padding-left: @8px;
    &.disabled {
      color: lighten(@inputColor, 50%);
      cursor: default;
    }
  }
}
