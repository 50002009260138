@import (reference) '~theme/globals/site.variables';

.plain-input-search-box {
  &.as-accordion {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-start;

    & > div {
      flex: 1 1;
    }

    & > button {
      margin: @2px 0 0 @10px;
    }

    .code-editor-control {
      width: 6 * @10px;
      height: 100%;
    }
    .ui.styled.searchbox.accordion {
      .title {
        padding: @6px 0.25em;
      }

      .content {
        padding: 0.5rem;

        .ui.form {
          margin: 0;
        }
      }
    }
  }

  &:not(.as-accordion) {
    &.plain {
      button.ui.button.switch-mode {
        background: transparent;
        border: solid 1px fade(@grey, 20%);
        margin-left: @6px;
      }

      display: inline-flex;
      flex-direction: row-reverse;
      vertical-align: middle;
      flex-grow: 1;

      .code-editor-control {
        flex-grow: 1;
      }

      .ui.switch-mode {
        margin: 0 0 0 0.5rem;
      }
    }

    &:not(.plain) {
      .ui.switch-mode {
        position: absolute;
        right: @37px !important;
        top: @6px;
      }
    }    
  }
}
