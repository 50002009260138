@import (reference) '~theme/globals/site.variables';
@import (reference) '../ProbeDetails/ProbeGraph/ProbeGraph.less';
@import '~apstra-ui-common/styles/colors.less';

.processor-modal {
  .processor-editor {
    height: 100%;
    overflow-y: scroll;
    padding-right: @28px;
    .scrollable-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: @10px;

      .input-arrow, .output-arrow {
        color: @stageColor;
      }
    }
  }
}

.processor-editor-input-card {
  --card-color: @stageColor;
  .input-card-item {
    position: relative;
    .input-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    padding-right: @20px;
    .description-icon {
      position: absolute;
      top: @11px;
      right: 0;
    }
  }
}

.processor-editor-processor-card {
  --card-color: @processorColor;
  svg.iba-processor-icon {
    height: 1rem;
    width: @16px;
    margin-right: @4px;
  }
  .processor-name.input {
    flex: 1;
  }
}

.processor-editor-output-card {
  --card-color: @stageColor;
  .card-content {
    padding-top: @10px;
    .description {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: @20px;
      .description-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .output-name.input {
    flex: 1;
  }
}
