@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../variables';

@paddingTop: 12px;

.ui.grid {
  .row.roles-header-sticky {
    position: sticky;
    top: @headerNavigationHeight + @paddingTop;
    z-index: 3;
    background-color: @white;
  }
}

.with-cluster-message {
  .ui.grid {
    .row.roles-header-sticky {
      top: @headerNavigationHeight + @globalClusterMessageHeight + @paddingTop;
    }
  }
}
