@import (reference) '~theme/globals/site.variables';
@import (reference) '../../styles/variables.less';

.summary-info {
  display: grid;
  grid-row-gap: @28px;
  .block {
    display: inline-grid;
    grid-template-columns: @40px minmax(0, 1fr);
    grid-column-gap: @20px;
    align-items: center;
    .icon {
      width: @40px;
      height: @40px;
    }
    .meta {
      .ui.header {
        font-size: @32px;
        margin-bottom: 0;
      }
      .sub.header {
        color: @grey7;
        font-size: @14px;
      }
    }
  }
}
