@import (reference) '~theme/globals/site.variables';

.ui.grid.endpoints-map-view {
  > .row {
    padding-bottom: 0.5rem;
    &.search {
      padding-bottom: 0;
    }
  }

  h3.header {
    display: inline-block;
    margin: 0 0 0.8em;
    .sub.header {
      display: inline-block;
      margin-left: 1em;
      font-weight: 400;
      color: lighten(@textColor, 40%);
    }
  }

  .legend {
    padding-top: 6px;
    .item {
      margin-right: 0.5em;
      > div {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 0.5em;
        position: relative;
        top: 2px;

        &.teal {
          background-color: @teal;
        }

        &.violet {
          background-color: @violet;
        }

        &.grey {
          background-color: @grey;
        }

        &.blue {
          background-color: @blue;
        }
      }
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  .port-maps {
    margin-bottom: 2em;
    > div:not(:last-child) {
      margin-bottom: 1.5em;
    }
    > div > .port-map:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}
