@import "../variables.less";

.ct {
  stroke-width: 0;

  &:not(.disabled) {
    // Primitives with no connections to the Application Point
    &.disconnected {
      .container {
        fill: @eptDisconnected !important;
      }

      text.title {
        fill: @black;
      }

      // ... and active
      &.active .container {
        stroke: @eptStroke !important;
      }
    }

    // Primitive with error in parameters
    &.error {
      .container {
        fill: @eptIncomplete;
      }

      // ... and active
      &.active .container {
        stroke: @eptErrorStroke;
      }
    }
  }

  &.disabled {
    .container {
      opacity: 0.9;
    }
    &.active .container {
      stroke: @eptStroke;
    }
  }

  // Primitive filtered out by the search
  &.dimmed {
    .container {
      opacity: 0.2;
    }
    text.title {
      fill: @grey;
    }
  }

  // Currently selected primitive
  &.active {
    .container {
      stroke: @eptStroke;
    }
  }

  .container {
    width: @eptWidth;
    height: @eptHeight;
    fill: @eptNormal;
    stroke: transparent;
    stroke-width: 3;

    &:hover {
      fill-opacity: 0.8;
    }
  }

  text {
    &.title {
      font-size: 12px;
      clip-path: inset(0 calc(50% - @eptWidth / 2 + 5px) 0 calc(50% - @eptWidth / 2 + 5px));
      fill: @white;
      user-select: none;
    }
  }
}

.ct-canvas.diff .ct.disabled {
  // In diff view added primitives
  &.added {
    .container {
      fill: @eptAdded;
    }
    &.active .container {
      stroke: darken(@eptAdded, 15);
    }
  }

  // In diff view deleted primitives
  &.deleted {
    .container {
      fill: @eptDeleted;
    }
    &.active .container {
      stroke: darken(@eptDeleted, 15);
    }
  }

  // In diff view same primitives with changed parameters
  &.different {
    .container {
      fill: @eptChanged;
    }
    &.active .container {
      stroke: darken(@eptChanged, 15);
    }
  }
}

.ct-remove, .ct-settings {
  fill: fade(@black, 30);
  cursor: pointer;
  stroke: @white;
  stroke-width: 2;
  paint-order: stroke;
  font-family: @iconsFontFamily !important;

  &:hover {
    fill: @black;
  }
}

.ct-settings {
  transform: translate(@eptWidth - 30px, -6px);
}

.ct-remove {
  transform: translate(@eptWidth - 12px, -6px);
}

.draggable {
  .drag {
    .ct .container {
      opacity: 0.8;
    }
  }
  .disabled {
    cursor: pointer;
  }
}

.different-only .ct {
  &:not(.added):not(.deleted):not(.different) {
    opacity: 0.6;
  }
}
