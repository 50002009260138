@grey1: #fafafa;
@grey2: #f5f5f5;
@grey3: #eeeeee;
@grey4: #e0e0e0;
@grey5: #bdbdbd;
@grey6: #9e9e9e;
@grey7: #757575;
@grey8: #616161;
@grey9: #424242;
@grey10: #212121;
