@import url("./const.less");

.logical-device-structure {
  .dashed-frame;
  display: flex;
  gap: 2rem;
  flex: 1 1;
  border-radius: 5px;
  padding: 0.5rem;

  &.spine > .logical-device {
    .block-top-label("Spine");
  }

  &.superspine > .logical-device {
    .block-top-label("Superspine");
  }

  .logical-device {
    .block-top-label("Logical Device");

    display: flex;
    flex: 0 0;
    min-width: @structure-block-width;
    align-items: center;

    label {
      .overflown-block();
      .logical-entity();
      font-size: 0.9rem;

      &[role="button"] {
        cursor: pointer;
      }
    }
  }

  div.device-profiles {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;

    &:empty:before {
      content: "Not defined";
      color: @red;
    }
  }
}