@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../../styles/variables';

@overallReportPadding: 80px;

.report-toc {
  top: 0;
  max-height: calc(100vh - @breadcrumbsHeight - @headerNavigationHeight - @footerContainerHeight - @overallReportPadding);
  overflow: auto;
  position: sticky;

  padding: 15px;
  background-color: @grey1;
  border-radius: 5px;

  .toc-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: 5px;
  }

  .toc-chapter {
    font-weight: @bold;
    margin-top: 10px;
  }

  .ui.list {
    margin-top: -10px;
    .item {
      a, &:before {
        color: @textColor;
      }
      &.hovered {
        & > a, &:before {
          color: @teal;
        }
      }
      &.selected {
        & > a.toc-item {
          color: @white;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: @teal;
            border-radius: 2px;
            z-index: -1;
          }
        }
      }
    }

    & > .item, & .list > .item {
      padding: 0;
      &:first-child {
        padding: 0;
      }
    }
    .list {
      padding: 0 0 0 10px;
    }
  }

  .ui.ordered.list .list > .item > .list > .item:before {
    margin-left: -2.75em;
  }
  .ui.ordered.list .list > .item > .list > .item > .list > .item:before {
    margin-left: -4em;
  }
}
