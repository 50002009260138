@import (reference) '~theme/globals/site.variables';
@import '~apstra-ui-common/styles/colors.less';

.processor-modal {
  .tree-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-input {
      margin-bottom: @20px;
    }
    
    .processor-selector-tree {
      --card-color: @grey4;
      color: @black !important;
      height: 100%;
      .card-content {
        height: 100%;
        overflow-y: auto;

        .item {
          &:not(:first-child) .top-level{
            padding-top: @20px;
          }
          >.list {
            padding-left: 0;
          }
          .top-level {
            font-size: @16px;
            font-weight: 700;
            border-bottom: 1px solid @grey4;
            padding-bottom: @10px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          .category {
            font-weight: 700;
            margin-left: @15px;
            &.fade {
              color: @grey7 !important;
            }
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          &.processor {
            cursor: pointer;
            font-weight: 400;
            font-size: @12px;
            padding: @5px !important;
            border-radius: @5px;
            margin-top: @10px;
            margin-left: @25px;
            display: flex;
            align-items: center;
            gap: @4px;
            &.fade {
              color: @grey7 !important;
            }

            &:hover {
              color: @grey6 !important;
            }

            &.selected {
              font-weight: 700;
              background-color: @grey4;
            }

            svg.iba-processor-icon{
              height: 1rem;
              width: @16px;
            }
          }
        }
      }
    }
  }
}
