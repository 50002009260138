@import (reference) '~theme/globals/site.variables';

.ui.form .input-header.field {
  margin-bottom: 0;
  & > .fields {
    margin-bottom: 0 !important;
  }

  .hidden-icon {
    opacity: 0;
    height: 0;
  }
}

.ui.form .input-subheader {
  & label {
    font-size: @12px;
    margin-bottom: 0;
  }
}

.input-component-wrapper {
  display: flex;
  align-items: flex-start;
  > .input-component {
    flex-grow: 1;
  }

  > .icon {
    margin-top: @2px;
  }
}
