.eptSegment { // stylelint-disable-line selector-class-pattern
  &.ui.segment {
    margin-top: 0;
  }

  ul {
    padding-left: 0;

    li {
      margin-bottom: 15px;
      list-style: none;

      h4 {
        margin: 0;
      }
    }
  }
}
