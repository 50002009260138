.link-editor-modal {
  .link-title {
    .checkbox label {
      padding-left: 0;
      &:before {
        visibility: hidden;
        content: none;
      }
    }
  }
}
