@import "../variables.less";
@import "../../../styles/pages/domain/devices/device-profiles/PortMapView.less";

.single-link {
  & .link-title {
    width: 100%;
    margin-top: 10px;

    background-color: @white;
    display: flex !important;

    h4 {
      flex: 3 1;
      margin-bottom: 0;

      .ui.checkbox {
        margin-top: 8px;

        label {
          font-size: 1.3rem;
        }
      }
    }

    div.tags {
      flex: 3 1;
      align-self: baseline;
      white-space: nowrap;

      span {
        font-weight: 600;
      }

      div.field {
        display: flex;
        align-items: baseline;

        label {
          padding-right: 5px;
        }

        div.ui.multiple.fluid.dropdown {
          font-size: 0.9rem;

          & > a.ui.label {
            height: 1.5rem;
          }
        }
      }
    }

    div.actions {
      flex: 0 1;
      padding-left: 15px;
      align-self: top;

      & > .ui.button {
        margin: 3px 0 0 4px;
      }
    }
  }

  div.ui.error.message {
    ul {
      padding-left: 10px;
    }
  }

  .ui.grid.port-maps {
    .row {
      &.header {
        padding-bottom: 0;
      }

      &.three > .column {
        &.external div.checkmark {
          display: flex;
          justify-items: center;
          align-items: center;
          height: 100px;

          i {
            font-size: 2em;
            flex: 1;
            margin-top: -10px;
            margin-bottom: 15px;
          }
        }

        &:first-child, &:nth-child(3) {
          width: calc(50% - 15px) !important;
        }

        &.ctrl-divider {
          width: 30px !important;
          padding: 0;

          div {
            display: flex;
            flex-direction: column;
            width: 30px;
            height: 100%;

            div {
              flex: 1 1;
              background: linear-gradient(@dividerColor, @dividerColor) no-repeat center/1px 100%;
            }
            span {
              display: block;
              flex: 0 1;
              text-align: center;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
