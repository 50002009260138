@import "../variables.less";

.ct-side-panel {
  flex: 0 0 350px;
  padding-right: 15px;

  &.as-tabs {
    flex-basis: 500px;
  }

  .tab {
    max-height: @canvasHeight - 45px;
    overflow-y: auto;
  }

  .accordion {
    div.title:not(:first-child) {
      border-top: solid 1px @eptParametersDividerColor;
    }
  }
}
