@import './variables.less';

.datacenter-topology {
  width: 100%;
  position: relative;
  .datacenter-topology-container {
    position: relative;
    border: 1px solid @borderColor;
    border-radius: @defaultBorderRadius;
    width: 100%;
    overflow: hidden;
    svg {
      stroke: @black;
      fill: @black;
      text-rendering: optimizeSpeed;
    }

    .topology-transformer {
      transform-origin: 0 0;
      position: absolute;
      &.zoom-transition {
        transition: transform 200ms linear;
      }
    }

    each(@roles, {
      @role: @value;
      .tp-node-@{role} {
        @colorVariable: "@{role}TextColor";
        @backgroundColorVariable: "@{role}BackgroundColor";
        fill: @@backgroundColorVariable;
        .text {
          color: @@colorVariable;
        }
        &:hover {
          fill: darken(@@backgroundColorVariable, 5);
        }
      }
    });

    .tp-status(@backgroundColor; @textColor: @invertedTextColor) {
      fill: @backgroundColor !important;
      .text {
        color: @textColor !important;
      }
      &:hover {
        fill: darken(@backgroundColor, 5) !important;
      }
    }

    .tp-status-dark-red { .tp-status(darken(@red, 20%)); }
    .tp-status-red { .tp-status(@red); }
    .tp-status-orange { .tp-status(@orange); }
    .tp-status-green { .tp-status(@green); }
    .tp-status-yellow { .tp-status(@yellow; @textColor); }
    .tp-status-teal { .tp-status(@teal); }
    .tp-status-purple { .tp-status(@purple); }
    .tp-status-grey { .tp-status(@grey); }

    .topology-interface {
      cursor: pointer;
      &:hover {
        stroke-width: 2px;
        stroke: @grey;
      }
    }

    .tp-node-remote-gateway {
      fill: @white;
      stroke: @remoteGatewayBorderColor;
      stroke-dasharray: 3 2;
      stroke-width: 2px;
      .text {
        color: @remoteGatewayTextColor !important;
      }
      &:hover {
        stroke-dasharray: none;
        stroke: darken(@remoteGatewayBorderColor, 30);
      }
    }
  }
}
