@import (reference) '~theme/globals/site.variables';

.graph-link {
  cursor: pointer;
  .link {
    fill: none;
    stroke: rgb(0, 0, 0);
    opacity: 0.4;
    stroke-width: 1px;
  }
  &.aggregated .link {
    stroke-dasharray: 5px;
  }
  &:not(.dragging) {
    &:hover .close-button {
      opacity: 1;
    }
    &:hover, &.highlighted {
      .link {
        stroke-width: 2px;
        opacity: 0.6;
      }
    }
  }
  &.dragging {
    pointer-events: none !important;
  }
  .close-button {
    fill: @white;
    stroke: lighten(rgb(0, 0, 0), 40%);
    stroke-width: 2px;
    opacity: 0;
  }
}
