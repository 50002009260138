@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.iba-dashboard-preview {
  display: flex;
  flex-direction: row;
  max-width: 35vw;
  .iba-dashboard-preview-column {
    min-width: 0;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    > div {
      flex-grow: 1;
      flex-basis: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .iba-widget-preview {
    border-radius: @borderRadius;
    border: 1px dashed @lightGrey;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    > div {
      min-width: 0;
    }
    .widget-label {
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
