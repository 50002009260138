@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/button.variables';
@import (reference) '../../../../styles/variables';

@padding: 20px;

div.row.report-row {
  flex-wrap: nowrap;

  .column {
    &.report-toc-column {
      @media print {
        display: none;
      }
      padding: 0;
      flex-basis: 320px;
      margin-right: 30px;
    }

    &.report-column {
      flex-grow: 1;

      @media screen {
        padding: 20px 20px 0 20px;
        box-shadow:
          0 0 1px 0 rgba(0, 0, 0, 0.01),
          0 0 3px 0 rgba(0, 0, 0, 0.02),
          0 0 5px 0 rgba(0, 0, 0, 0.02),
          0 0 8px 0 rgba(0, 0, 0, 0.02),
          0 0 15px 0 rgba(0, 0, 0, 0.03),
          0 0 36px 0 rgba(0, 0, 0, 0.04);
      }

      @media print {
        flex: 0 0 1024px;
      }

      .ui.divider {
        margin-top: 0;
        margin-bottom: @padding;
      }

      p {
        margin-bottom: @padding;
      }

      .ui.report-header.header {
        scroll-margin-top: @breadcrumbsHeight + @headerNavigationHeight;
        margin: 0;
        padding-bottom: @padding;
        font-weight: normal;

        &.expanded {
          color: @teal;
          & svg > path {
            fill: @teal;
          }
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }
    
      .report {  
        .title {
          display: flex;
          align-items: center;
          padding-bottom: @padding;
        }
        .report-chart {
          padding-bottom: @padding;

          & > .donut-chart-wrapper, & > .sunburst-chart-wrapper {
            justify-content: center;
          }
        }
      }
    
      .report-chart, .report-section {
        &.report-collapsed {
          display: none;
        }
      }
    
      .ui.header > div, .report > .title {
        & > .ui.basic.button {
          font-size: @16px;
          color: @black;
          padding: 0;
          margin-right: @padding / 2;
      
          &, &:active, &:focus {
            box-shadow: none;
          }
      
          .chevron-icon {
            width: 16px;
            height: 16px;
            transform: translateY(2px);

            &.expanded {
              transform: rotate(90deg) translateX(2px);
            }
          }
        }
      }
    }
  }
}

.ui.button.report-top-scroll {
  @media print {
    display: none;
  }
  position: fixed;
  bottom: 0;
  right: 10px;
  margin-bottom: 1em;
}
