@import (reference) '~theme/globals/site.variables';

div.scatter-plot-chart {
  svg {
    line.trend {
      stroke-width: 2px;
      stroke: @black;
      opacity: 0.5;
    }
    circle.plot {
      cursor: pointer;

      &:not([fill]) {
        fill: fade(@grey, 80%);
      }
    }
  }
}
