@import (reference) '~theme/globals/site.variables';

.ui.form .field {
  &.query-tag-filter {
    > .fields {
      margin-bottom: 0 !important;
    }
  }
}

.ui.form .query-tag-filter-header.field {
  margin-bottom: 0;
  & > .fields {
    margin-bottom: 0 !important;
  }

  .hidden-icon {
    opacity: 0;
    height: 0;
  }
}

.ui.form .query-tag-filter-subheader {
  & label {
    font-size: @12px;
  }
}
