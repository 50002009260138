@import (reference) '~theme/globals/site.variables';

.port-details.compact {
  .ui.label.small:not(.grey) {
    cursor: pointer;

    &.basic:hover {
      background: lighten(@lightGrey, 8%) !important;
    }

    &:not(.basic):hover {
      color: lighten(@textColor, 50%) !important;
    }
  }
}

.slot-container:not(:first-child) {
  margin-top: 1em;
}

i.legend {
  color: rgb(102, 102, 102);
  font-weight: 300 !important;
}

.legends {
  margin-bottom: 1em;
  text-transform: none;

  > div {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 0.5em;
    }

    .icon {
      &.clockwise {
        position: relative;
        top: 3px;
        margin: 0;
      }
    }

    span {
      font-weight: 300;
      padding-left: 0.3em;
    }
  }
}

.device-profile-port-map-container {
  overflow-x: auto;
  overflow-y: hidden;

  .react-resizable {
    margin-bottom: 10px;

    .react-resizable-handle {
      bottom: -10px;
      right: -8px;
      border-bottom: 4px solid @grey;
      border-right: 4px solid @grey;
      background: none;
      padding: 0;
      width: 16px;
      height: 16px;
      cursor: se-resize;
    }
  }

  table.device-profile-port-map {
    border-collapse: collapse;
    white-space: nowrap;

    .port {
      display: inline-block;
      text-align: center;
      user-select: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
      width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 1;
      overflow: visible;
      position: relative;
      border: 1px solid lighten(@grey, 45%);

      span {
        display: block;
        font-weight: 300;
        height: 100%;

        &.port-id {
          line-height: 3;
          font-size: 0.8rem;

          & + .icon {
            position: absolute;
            right: 2px;
            top: 0;
            margin: 0;
            font-size: 0.9rem;
            font-weight: bolder;
          }
        }

        & + i.icon {
          color: @white;
        }

        .autoneg {
          display: block;
          position: absolute;
          left: 2px;
          top: -9px;
          font-size: 0.7rem;
          color: @white !important;
          font-weight: bolder;
        }
      }

      &.inverted {
        background-color: @grey;
        border: 1px solid @white;
        span {
          color: @lightGrey;
          font-weight: 600;
        }
        &:not(.selected) {
          opacity: 0.5;
        }
        &.cell-selected {
          opacity: 0.8 !important;
          span {
            color: @white;
            font-weight: 600;
          }
        }
        &:hover:not(.summary) {
          opacity: 1;
          span {
            color: @white;
          }
        }
      }

      &.cell-disabled {
        cursor: not-allowed;
      }

      &.selected, &.cell-selected, &.no-summary:hover {
        span {
          color: @white;
          font-weight: bold;
          filter: brightness(130%);
        }
      }

      &.summary {
        cursor: default;
      }

      &.cell-being-selected {
        filter: brightness(110%);

        span {
          color: @black;
          font-weight: bold;
        }
      }

      &.error span {
        color: @lightRed;
        font-weight: bold;
      }
    }
  }
}