div.rt-description {
  min-width: 400px;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;

  details {
    summary {
      list-style: none;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;

      &:before {
        content: '\25B8';
        display: inline-block;
        width: 14px;
        opacity: 0.4;
      }
    }

    &[open] > summary:before {
      content: '\25BE';
    }

    dl {
      margin: 0 0 0 12px;
      display: flex;
      dt {
        flex: 1 1;
        font-weight: bold;
        white-space: nowrap;

        &:after {
          content: ':';
        }
      }
      dd {
        flex: 1 1;
        margin: 0 0 0 4px;
        white-space: nowrap;
      }
    }

    details {
      margin-left: 10px;
    }
  }
}
