@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../styles/variables';

.boxplot-chart.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > svg {
    width: 100%;
  }
  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }
  .timeline-axis {
    .visx-axis-line, .visx-axis-tick > .visx-line {
      stroke: @grey;
      opacity: 0.5;
      stroke-width: 1px;
      pointer-events: none;
    }
    .visx-axis-tick text {
      fill: @grey;
      font-size: 10px;
    }
    &.axis-bottom {
      .visx-axis-tick text {
        text-anchor: middle;
      }
    }
    &.axis-left {
      .visx-axis-tick text {
        text-anchor: end;
      }
    }
  }
  .timeline-grid line {
    stroke: @grey;
    stroke-dasharray: 2px;
    opacity: 0.2;
    pointer-events: none;
  }
  .axis-label {
    font-size: 10px;
    fill: @grey;
  }

  .boxplot-summary {
    fill: @blue;
    fill-opacity: 0.1;
    stroke: @black;
    stroke-width: 1;

    &:hover {
      fill-opacity: 0.5;
    }
  }

  .boxplot-points {
    & > g.boxplot-dots > circle {
      fill: lighten(@black, 60%);
      stroke-width: 0;
      fill-opacity: 1;
    }
  }
}
