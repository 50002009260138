@import (reference) '~theme/globals/site.variables';

.python-exression-suggestions {
  > .python-exression-suggestion {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    padding-right: 10px;
    > span {
      text-decoration: underline;
      text-decoration-style: dashed;
      color: @black;
    }
  }
}
