@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.stage-widget-customization-input {
  .ui.message {
    display: flex !important;
  }
}

.ui.table.stage-widget-customization-table {
  margin-top: 0;
  tr {
    &.dragging {
      background-color: @darkWhite;
      border-top: @borderWidth solid @darkWhite !important;
      border-bottom: @borderWidth solid @darkWhite !important;
      td {
        background: none !important;
        &:not(:first-child) {
          visibility: hidden;
        }
      }
    }
    &.invisible {
      td:first-child {
        color: @unselectedTextColor;
      }
    }
    td:first-child {
      padding-left: @cellHorizontalPadding !important;
    }
  }
  thead + tbody > tr:first-child > td {
    border-top: @borderWidth solid @borderColor;
  }
  tbody + tbody > tr:first-child > td {
    border-top-width: @borderWidth * 2;
  }
  thead + tbody:last-child > tr:last-child > td {
    border-bottom: @borderWidth solid @white;
  }
  .ui.checkbox {
    margin-top: 2px;
  }
}
