@import (reference) '~theme/globals/site.variables';
@import (reference) '../../styles/variables.less';

@light-blue: lighten(@blue, 60%);
@light-green: lighten(@green, 50%);

.query-brick {
  display: grid;
  grid-row-gap: @10px;

  & > {
    .ui.buttons {
      .ui.icon {
        background: none;
      }
    }
  }

  &.wrapper {
    padding: @10px @15px;
    border-radius: @5px;
    background-color: @grey2;
    &.query-relationship {
      background-color: @light-blue;
      border-color: @light-blue;
      .condition-divider {
        .fields:after {
          background: @light-blue;
        }
      }
    }
    &.query-node-brick {
      background-color: @light-green;
      border-color: @light-green;
      .condition-divider {
        .fields:after {
          background: @light-green;
        }
      }
    }
  }

  &.bordered {
    border: 1px solid @grey5;
  }
  &.positive {
    background-color: @grey1;
  }

  .ui.tiny.button {
    justify-self: flex-start;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: @16px;
    font-weight: 700;

    & > span {
      display: inline-flex;
      align-items: center;
    }
  }

  .ui.dropdown {
    .item {
      display: flex;
      flex-direction: column-reverse;
      .description {
        float: initial;
        margin: @10px 0 0 0;
        word-break: break-word;
      }
    }
  }
}
