@import (reference) '~theme/globals/site.variables';
@import (reference) '../ProbeDetails/ProbeGraph/ProbeGraph.less';

.processor-name {
  th, td {
    color: @stageColor !important;
  }
  .processor-modal-card {
    width: 500px;
    .card-title {
      font-size: @16px;
    }
  }
  .processor-inputs, .processor-outputs {
    display: flex;
    gap: @10px;
    justify-content: center;
    table {
      border-collapse: separate;
      border-spacing: @10px;
      margin: -@10px !important;
      width: calc(100% + @20px);
      th,tr {
        border: 0 !important;
      }
      th {
        padding: 0;
      }
      td {
        background-color: color-mix(in sRGB, @stageColor 10%, @white) !important;
        border: 1px solid @stageColor !important;
        border-radius: @5px;
      }
    }
  }
  .processor-editor-processor-card {
    margin-inline: auto;
    width: 100%;
  }
  .processor-row {
    display: flex;
    gap: @10px;
    > :nth-child(1) {
      flex: 1;
    }
    > :nth-child(2) {
      flex: 2;
    }    
  }
  .card-arrow-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    .processor-modal-card {
      flex: 1;
    }
    > svg {
      color: @stageColor;
      margin-block: @10px;
    }
  }
}
