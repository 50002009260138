@import "../variables.less";

div.re-ports {
  display: flex;
  margin: 2px -2px 0 !important;
  padding: 2px;
  white-space: nowrap;

  background-color: fade(@grey, 10%);
  border-radius: 4px;

  & > div {
    display: flex;
    align-items: center;

    & > .re-port {
      flex: 0 0 @portSize;
    }

    ul {
      flex: 1 1;
      margin: 0;
      padding: 0 5px 0 24px;
      min-width: 50px;
      font-size: 0.7rem;
      line-height: normal;

      &.no-count {
        padding-left: 5px;
      }

      li {
        list-style: none;
        white-space: normal;

        b {
          display: inline-block;
          width: 23px;
          position: absolute;
          margin-left: -25px;
          text-align: right;

          &:after {
            content: ' × ';
          }
        }
      }
    }
  }

  &.error {
    background-color: lighten(@red, 40%);
  }
}
