@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/elements/loader.variables';
@import (reference) '~theme/collections/form.variables';

// loaders should be visible inside modals
.ui.modal .ui.loader {
  color: @textColor !important;
  &:before {
    border-color: @loaderFillColor !important;
  }
  &:after {
    border-color: @shapeBorderColor !important;
  }
}

// do not highlight modal when auto-focused
.ui.modal[tabindex="0"]:focus {
  outline: none;
}

// messages should be visible inside forms
.ui.form .message:not(:empty) {
  display: block;
}
.ui.form .compact.message:not(:empty) {
  display: inline-block;
}
.ui.form .icon.message:not(:empty) {
  display: flex;
}

// sortable table headers shouldn't be selectable
.ui.sortable.table thead th {
  user-select: none;
}

// fix hovered items in error dropdown
.ui.form .fields.error .field .ui.dropdown .menu .item:hover .text,
.ui.form .field.error .ui.dropdown .menu .item:hover .text,
.ui.form .fields.error .field .ui.dropdown .menu .selected.item .text,
.ui.form .field.error .ui.dropdown .menu .selected.item .text {
  background-color: @dropdownErrorHoverBackground;
}

// fix menu border in error dropdown
.ui.form .fields.error .field .ui.dropdown .menu,
.ui.form .field.error .ui.dropdown .menu {
  border-color: @formErrorBorder;
}

// Hide arrows from input number
.ui.dropdown.search.selection {
  input.search[type=number] {
    // Firefox
    appearance: textfield;
    // Chrome, Safari, Edge, Opera
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

// fix firefox bug- unable to click up/down buttons
// of numeric input with large right padding
.ui.input input[type="number"] {
  padding-right: 0.1em;
}

// align items in accordion regardless of parent's text-align
.ui.accordion {
  text-align: left;
}

.ui.floating.label {
  z-index: 1;
}
