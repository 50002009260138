@import (reference) '~theme/globals/site.variables';

.logical-device-port-map-container {
  overflow-x: auto;
  overflow-y: hidden;

  .react-resizable {
    margin-bottom: 10px;

    .react-resizable-handle {
      bottom: -10px;
      right: -8px;
      border-bottom: 4px solid @grey;
      border-right: 4px solid @grey;
      background: none;
      padding: 0;
      width: 16px;
      height: 16px;
      cursor: se-resize;
    }
  }

  table:not(.ui):not(.table) {
    table-layout: fixed;

    tr {
      display: table-row !important;

      .port, .ui.segment.port {
        cursor: default;
        line-height: 1;
        overflow: visible;
        margin: 0;
        padding: 0 !important;
        text-align: center;
        user-select: none;
        border-radius: 0;
        border: 1px solid @white !important;
        display: table-cell !important;

        &.unassigned {
          span {
            background-color: @grey;
          }

          &.selected {
            span {
              color: @black;
              font-weight: bold;
              background-color: @lightGrey;
            }
          }
        }

        &.grayed {
          filter: brightness(65%);
        }

        span {
          color: @white;
          font-size: 9pt;
          line-height: 2.5;
          display: block;
          font-weight: 100;
        }
      }
    }

    &.summary {
      @compactPortSize: 15px;
      tr > td.port {
        width: @compactPortSize !important;
        height: @compactPortSize !important;
      }
    }
  }
}
