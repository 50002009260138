.ui.list.interface-policy-port-map-legend {
  padding-top: 6px;
  > .item {
    margin-right: 0.5em;
    > div {
      width: 15px;
      height: 15px;
      display: inline-block;
      margin-right: 0.5em;
      position: relative;
      top: 2px;

      .endpoint-color(@color, @lightening: 40%) {
        border: 1px solid @color;
        background: lighten(@color, @lightening);
      }

      .endpoint-color(@grey, 80%);

      &.teal {
        .endpoint-color(@teal);
      }

      &.violet {
        .endpoint-color(@violet, 20%);
      }

      &.grey {
        .endpoint-color(@grey);
      }

      &.blue {
        .endpoint-color(@blue, 10%);
      }
    }
  }
}
