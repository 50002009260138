.restraw {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .response-json {
    overflow-x: auto;
  }
}
