@import (reference) '~theme/globals/site.variables';

.anomaly-matrix {
  fill: rgba(0, 0, 0, 0.87);
  font-size: 10px;

  .header text {
    font-weight: bolder;
    font-size: 14px;
    text-anchor: middle;
  }

  .row {
    cursor: pointer;
    text {
      font-size: 12px;
      font-weight: bold;
    }
    line {
      stroke: @whiteDown;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }
    .row-name {
      font-weight: bolder;
      text-anchor: end;
    }
    .row-sum {
      fill: none;
    }
  }

  .anomaly {
    &:hover text {
      visibility: visible;
    }
    text {
      visibility: hidden;
      text-anchor: middle;
      &.white {
        fill: @white;
      }
      &.black {
        fill: @black;
      }
    }
    circle {
      fill: @red;
      stroke-width: 1.5px;
      transition: transform 500ms;
    }
  }

  .row.selected {
    line {
      stroke: @red;
      stroke-width: 1px;
    }
    .row-sum, .row-name {
      fill: @red;
    }
    .anomaly text {
      visibility: visible;
    }
  }

  .no-anomalies {
    font-size: 36px;
    fill: @lightTextColor;
    text-anchor: middle;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
}
