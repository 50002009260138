@import "../../variables.less";

.ctrl-lag {
  rect {
    fill: none;
    stroke: lighten(@grey, 50%);
    stroke-width: 2px;

    &.outline {
      stroke-width: 3px;
      stroke: @white !important;
    }

    &:focus {
      outline: solid 4px  @blue;
      border-radius: 50%;
    }
  }

  &.behind rect {
    stroke: lighten(@grey, 40%);
    fill: transparent;
  }

  &.interactive {
    cursor: pointer;
  }

  &.error rect:not(.outline) {
    stroke: lighten(@red, 20%) !important;
  }

  &.error.behind rect:not(.outline) {
    stroke: @red !important;
  }

  &.selected rect:not(.outline) {
    stroke: lighten(@black, 60%);
  }

  &.selected.behind rect:not(.outline) {
    stroke: @black;
  }
}
