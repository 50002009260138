div.ct-diff-properties.column {
  overflow-y: auto;
  overflow-wrap: break-word;
  max-height: 500px;
}

div.canvas-container.column {
  max-height: 500px;
  overflow: auto;
}
