@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.ui.grid.probe-stage {
  margin: 0 !important;
  padding: 0 !important;

  .probe-contents-header .ui.label {
    margin-left: 0;
  }

  & > .row {
    padding-bottom: 0 !important;
    padding-top: @10px;

    &:first-child {
      padding-top: 0;

      & > div.create-button.column {
        & > div.menu {
          margin-top: -8px;
        }
      }
    }

    & > .column {
      margin: 0;
      padding: 0;
    }
  }

  .stage-icon {
    fill: @textColor;
    height: 13px;
    width: 13px;
    margin-right: 8px;
    vertical-align: top;
  }
}
