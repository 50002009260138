@import "../../variables.less";
@import "./CtSearch.less";

.ct-primitive-parameters {
  height: 300px;

  & > button.ui.icon.button {
    position: absolute;
    margin: 16px 14px;
    right: 0;
    width: 20px !important;
    padding: 0;
    background: none;
  }

  & > .content {
    overflow-y: auto;
    padding: 0.5rem 0.7rem !important;

    .parameters button.btn-link {
      .ct-link-button();
      font-weight: normal;

      &.ct-add-condition-button {
        float: right;
        margin: 8px 0;
      }

      &.ct-any-value-button {
        float: right;
      }
    }

    .ui.list {
      &.conditions {
        padding: 0.5rem 0 0 0;
      }

      .item .content {
        padding: 0.2em 0;
      }

      .header {
        background-color: darken(@white, 3%);
        padding: 0.5em;
      }
    }
  }

  & > .header {
    background-color: darken(@white, 10%);
    padding: 1em;
  }
}

button.ui.circular.button.ct-search-logic {
  height: 40px;
  width: 40px;
  align-self: center;
  margin: 0 -20px !important;
  padding: 0;
  z-index: 10;
  border: solid 2px @white;
}
