@import (reference) '~theme/globals/site.variables';

.react-json-view {
  overflow-x: auto;
}

.rest-api-explorer-page {
  .path-details-method {
    text-transform: uppercase;
    margin-right: 20px;
  }
  .ui.menu.vertical.paths-menu {
    width: 100%;

    .paths-tree {
      padding-top: 0;

      .paths-tree-item {
        padding-left: 0.75em;
        .paths-tree-item-accordion {
          margin-top: 0;

          .paths-tree-item-accordion-title {
            &.selected {
              font-weight: bold;
              color: @teal;
            }

            &.without-content {
              padding-left: 28px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .paths-search {
    text-align: center;

    .paths-search-header {
      margin-bottom: 40px;
    }

    .search-box {
      width: 600px;
    }

    .suggestions-container {
      margin-top: 40px;

      .suggestion {
        margin-left: 10px;
      }
    }
  }

  .path-details {

    .path-details-description {
      white-space: pre-wrap;
    }

    .path-parameters-details-container {
      margin-top: 20px;

      .path-parameters-details {
        .path-parameters-details-header {
          font-weight: bold;
          font-size: 18px;
        }

        .table-header {
          font-size: 18px;
        }

        .table-header-divider {
          margin-top: 0;
          margin-bottom: 0;
        }

        .parameter-details {
          .parameter-name {
            font-weight: bold;

            &.required:after {
              content: "*";
              margin-left: 5px;
              font-size: 10px;
              color: @red;
            }
          }

          .parameter-type, .parameter-in {
            font-size: 12px;
            color: @grey;
          }

          .parameter-in {
            &:before {
              content: "( ";
            }

            &:after {
              content: " )";
            }
          }

          .parameter-content-type-label {
            font-weight: bold;
            margin: 10px 0 5px 0;
          }
        }
      }
    }

    .path-responses-details-container {
      margin-top: 40px;

      .path-responses-details {
        .path-responses-details-header {
          font-weight: bold;
          font-size: 18px;
        }

        .table-header {
          font-size: 18px;
        }

        .table-header-divider {
          margin-top: 0;
          margin-bottom: 0;
        }

        .response-content-type-container {
          text-align: right;

          > label {
            font-weight: bold;
            font-size: 16px;
            margin-right: 10px;
          }
        }

        .response-details {
          .response-code {
            font-weight: bold;
          }

          .response-description {
            font-weight: bold;
          }
        }
      }
    }

    @schemaExampleViewBackgroundColor: #e0e1e2;
    @schemaExampleViewColor: #5c5d5d;

    .schema-example-view {
      .__json-pretty__ { // stylelint-disable-line selector-class-pattern
        background: @schemaExampleViewBackgroundColor;
        padding: 5px;
      }
    }

    .schema-model-view {
      padding: 5px;
      background: @schemaExampleViewBackgroundColor;
      color: @schemaExampleViewColor;
      overflow-x: auto;

      .ui.accordion {
        .title {
          padding: 0;
        }
      }

      .schema-property-name {
        font-weight: bold;
        margin-right: 10px;

        &.required:after {
          content: "*";
          margin-left: 5px;
          color: @red;
        }
      }

      .ui.accordion.schema-object {
        margin-top: 0;

        &.open {
          > .title:after {
            content: "{";
          }

          &:after {
            content: "}";
          }
        }

        > .title:after {
          content: "{ ... }";
        }

        > .content {
          padding-top: 0;
          padding-left: 25px;
        }
      }

      .ui.accordion.schema-array {
        margin-top: 0;

        &.open {
          > .title:after {
            content: "[";
          }

          &:after {
            content: "]";
          }
        }

        > .title:after {
          content: "[ ... ]";
        }

        > .content {
          padding-top: 0;
          padding-left: 25px;
        }
      }

      .schema-primitive {
        padding: 0.3em 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        .schema-primitive-props {
          color: @grey;
          font-size: 12px;

          .schema-primitive-enum {
            &:before {
              content: "[ ";
            }

            &:after {
              content: " ]";
            }
          }
        }
      }
    }
  }
}
