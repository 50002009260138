.topology-node {
  cursor: pointer;
  stroke: none;
  .text {
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
    padding-left: 5px;
    padding-right: 5px;

    &.fallback-label {
      opacity: 0.5;
    }
  }
}
