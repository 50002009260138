@import (reference) '~theme/globals/site.variables';

.upload-notifier {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 999;
  width: 45%;

  .ui.message {
    border: 1px solid @strongTransparentBlack;
  }

  .ui.small.progress {
    margin: 0;
    min-width: 5em;
    > .bar {
      min-width: 3em;
    }
    > .label {
      font-weight: @normal;
    }
  }

  .name {
    > div {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .status {
    .error {
      margin-right: 5px;
      > i {
        margin-bottom: 3px;
      }

      .text {
        word-break: break-word;
      }
    }
  }
}
