@import (reference) '~theme/globals/site.variables';

g.rack-editor-zone {
  line {
    stroke-dasharray: 10px 10px;
    stroke: @teal;
    stroke-width: 1px;
    cursor: row-resize;

    &.outline {
      stroke: @white;
      stroke-dasharray: none;
      stroke-width: 5px;
    }
  }
  text {
    fill: @teal;
  }
}
