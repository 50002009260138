@import (reference) '~theme/globals/site.variables';

.chassis-profile-slot-map-container {
  overflow-x: auto;
  overflow-y: hidden;

  .react-resizable {
    margin-bottom: 10px;

    .react-resizable-handle {
      bottom: -10px;
      right: -8px;
      border-bottom: 4px solid @grey;
      border-right: 4px solid @grey;
      background: none;
      padding: 0;
      width: 16px;
      height: 16px;
      cursor: se-resize;
    }
  }

  table.chassis-profile-slot-map {
    border-collapse: separate;
    border-spacing: 2px;
    white-space: nowrap;

    .slot {
      background-color: @grey !important;
      text-align: center;
      user-select: none;
      border-radius: 0.35714286rem;
      padding: 0;
      margin: 0;
      width: 30px;
      height: 80px;
      cursor: pointer;
      line-height: 1;
      overflow: visible;
      position: relative;
      border: 1px solid lighten(@grey, 45%);

      span {
        display: block;
        font-weight: 300;
        height: 100%;

        &.slot-id {
          line-height: 7;
          font-size: 0.8rem;

          span.delete {
            line-height: 1;

            i.icon {
              opacity: 1 !important;
              font-size: 1.5em;
            }
          }

          & + .icon {
            position: absolute;
            right: 2px;
            top: 0;
            margin: 0;
            font-size: 0.9rem;
            font-weight: bolder;
          }
        }

        & + i.icon {
          color: @white;
        }

        .autoneg {
          display: block;
          position: absolute;
          left: 2px;
          top: -9px;
          font-size: 0.7rem;
          color: @white !important;
          font-weight: bolder;
        }
      }

      &.inverted {
        background-color: @grey;
        border: 1px solid @white;
        span {
          color: @white;
        }
        &.selected {
          opacity: 1;
          width: 60px;
          background-color: @white !important;
          border-color: @grey;
          border-width: 1px;

          span.slot-id {
            line-height: 3;
            color: @black;
            font-weight: bold;
            font-size: 1rem;
            opacity: 1;
          }
        }
        &:not(.selected) {
          opacity: 1;
        }

        &:hover:not(.selected):not(.nohover) {
          opacity: 0.5;
        }
      }

      &.cell-disabled {
        cursor: not-allowed;
      }

      &.selected, &.cell-selected, &.no-summary:hover {
        span {
          color: @black;
          font-weight: bold;
          filter: brightness(130%);
        }
      }

      &.cell-being-selected {
        filter: brightness(110%);

        span {
          color: @black;
          font-weight: bold;
        }
      }

      &.error span {
        color: @lightRed;
        font-weight: bold;
      }
    }
  }
}
