@import "../variables.less";

.connection-point {
  circle {
    r: @connectionPointRadius;
    stroke-width: 1;
    stroke: @white;
  }

  text {
    font-size: 9px;
    fill: @connectionPointLabelColor;
    stroke-width: 2;
    stroke: fade(@white, 80);
    paint-order: stroke;
  }

  &.in {
    circle {
      fill: @connectionPointInColor;
    }

    &.disabled circle {
      fill: lighten(@connectionPointInColor, 20);
    }

    text {
      transform: translate(-15px, -4px);
      text-anchor: end;
    }
  }

  &.out {
    circle {
      fill: @connectionPointOutColor;
    }

    &.disabled circle {
      fill: lighten(@connectionPointOutColor, 20);
    }

    text {
      transform: translate(15px, 10px);
    }
  }

  &.standalone {
    text {
      text-anchor: start !important;
    }
  }

  &.approached {
    circle {
      stroke: @orange;
      stroke-width: 3px;
    }
  }
}

.ct-linker {
  r: @connectionPointRadius - 3;
  stroke-width: 0;
  fill: @linkerBackground;
}

.draggable.drag {
  .ct-linker {
    fill: @linkerBackgroundDragged;
  }
}
