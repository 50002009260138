@import (reference) '~theme/globals/site.variables';

@interfaceFontSize: 10px;
@interfaceTextColor: @invertedTextColor;
@nodeBackgroundColor: #e2e7ee;
@checkBoxColor: #0096a4;

.neighbors-view .interface {
  color: @interfaceTextColor;
  font-size: @interfaceFontSize;
  text-align: center;
  border-radius: @defaultBorderRadius;
  cursor: default;
  position: relative;
  display: flex;
  align-items: center;

  > .label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2px;
    width: 100%;
  }

  > .ui.checkbox label:before {
    box-shadow: none;
  }

  > .ui.checkbox input:focus ~ label:before {
    background: @nodeBackgroundColor;
  }

  > .ui.checkbox label:hover:before {
    background: @nodeBackgroundColor;
  }

  > .ui.checkbox input:checked:focus ~ label:before {
    background: @checkBoxColor;
  }

  > .ui.checkbox input:checked ~ label:hover:before {
    background: @checkBoxColor;
  }

  > .ui.checkbox {
    margin-left: 3px;
  }

  .tp-interface {
    display: inline-block;
  }

  .icon.warning {
    float: right;
    display: none;
  }
}

.neighbors-traffic-interface-popup.popup {
  max-width: 500px;
}
