@import (reference) '~theme/globals/site.variables';

.legend {
  display: flex;
  > .legend-item {
    &:not(:first-child) {
      padding-left: 1em;
    }
    display: flex;
    align-items: center;
    line-height: 20px;
    > .icon, > .content {
      display: flex;
      height: 20px;
      margin: 0;
    }
    > .icon {
      &.dark-red {
        color: darken(@red, 20%) !important;
      }
    }
  }
}
