@import "../../variables.less";

.ct-searchbox {
  margin-bottom: 20px;
  width: auto !important;

  .cards {
    margin-bottom: 15px;
  }

  button.ct-search-logic {
    text-transform: uppercase;
  }

  .bottom-buttons {
    margin-top: 15px;
  }
}

.ct-link-button {
  background: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  text-decoration: underline;
  color: @teal;

  &:hover, &:focus {
    background-color: transparent;
  }
}
