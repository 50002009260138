@import (reference) '~theme/globals/site.variables';
@import (reference) '../../variables.less';

.main-content-wrapper {
  @media print {
    margin-left: 0;
    overflow: visible;
  }
  height: 100%;
  overflow: auto;
  background: @white;
  display: flex;
  flex-direction: column;
  &.with-cluster-message {
    & > .main-header {
      top: @globalClusterMessageHeight;
    }
    margin-top: @globalClusterMessageHeight;
  }
}

.main-header {
  @media print {
    display: none;
  }
  @mainHeaderBackgroundColor: @grey2;
  width: calc(100% - var(--sidebar-width));
  height: @breadcrumbsHeight;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  &.with-navigation {
    height: @breadcrumbsHeight + @headerNavigationHeight;
  }
  padding: 0 @contentSidePadding;
  background-color: @mainHeaderBackgroundColor;
  border-bottom: 1px solid @solidBorderColor;
  &.sticky {
    z-index: @layoutZIndex;
    top: 0;
    padding-left: @contentSidePadding;
    width: 100%;
  }
  .main-favorites {
    line-height: @breadcrumbsHeight;
    font-size: @16px;
  }
  .main-breadcrumbs {
    height: @breadcrumbsHeight;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin-right: 10px;
    &:before {
      @breadcrumbsOverflowWidth: 15px;
      content: '';
      width: @breadcrumbsOverflowWidth;
      height: @breadcrumbsHeight;
      position: absolute;
      right: 0;
      top: 0;
      background-image: linear-gradient(90deg, @transparentWhite, @mainHeaderBackgroundColor);
    }
    .ui.breadcrumb {
      font-size: @16px;
      line-height: @breadcrumbsHeight;
      > a {
        display: inline-block;
      }
      .divider {
        margin-right: 0;
      }
    }
  }
  .main-header-actions {
    height: @breadcrumbsHeight;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    .main-header-blueprint-actions {
      display: inline-block;
      > div {
        display: inline-block;
      }
      .lock-status-icon {
        display: inline-block;
        margin-left: 0.5em;
      }
    }
  }
  .main-header-navigation {
    width: 100%;
    height: @headerNavigationHeight;
    .ui.attached.tabular.menu {
      height: @headerNavigationHeight;
      > .item {
        width: 180px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: @16px;
        font-weight: @bold;
        background-color: @grey4;
        border-radius: @borderRadius @borderRadius 0 0;
        &:not(:first-child) {
          margin-left: 5px;
        }
        > .icon {
          font-size: @22px;
          &.apstra-icon {
            &:before {
              margin-bottom: @5px;
            }
            &.apstra-icon-dashboard {
              font-size: @relativeHuge;
            }
            &.apstra-icon-analytics {
              font-size: @relativeBig;
            }
            &.apstra-icon-active {
              font-size: @relativeMassive * 1.1; /* a bit bigger than @relativeMassive */
              margin-right: @7px; /* reduce a standard margin */
            }
            &.apstra-icon-time-voyager {
              font-size: @relativeLarge;
            }
          }
        }
        > .ui.floating.label {
          margin-left: -3em !important;
          padding: 0.5em;
          min-width: 25px;
          text-align: center;
        }
        &.active {
          background-color: @white;
          > .ui.floating.label {
            margin-top: -1px !important;
          }
        }
      }
    }
  }
}

.main-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  z-index: @layoutZIndex;
  background: @background;
  .collapsible-footer-container {
    .ui.header {
      display: block;
      padding: 0.5em @contentSidePadding;
      margin: 0;
      cursor: pointer;
      background-color: @lightGrey;
      .ui.loader {
        display: inline;
        margin-left: 25px;
      }
      .icon.caret {
        font-size: 1.2em;
      }
    }
    &.active {
      .ui.header {
        background-color: @teal;
        color: @invertedTextColor;
      }
    }
    > div {
      padding: 1em @contentSidePadding;
      max-height: 256px;
      overflow-y: auto;
    }
  }
}

.main-content {
  @media print {
    overflow: visible;
  }
  overflow: auto;
  @mainContentWithStickyPaginationBottomPadding: 160px;
  flex-grow: 1;
  flex-basis: 1;
  padding: @mainContentVerticalPadding @contentSidePadding;
  &.with-sticky-pagination {
    padding-bottom: @mainContentWithStickyPaginationBottomPadding;
  }
  &.with-step-by-step-guide-collapsed {
    padding-bottom: @mainContentVerticalPadding + @collapsedStepByStepGuideHeight;
  }
  &.with-sticky-pagination.with-step-by-step-guide-collapsed {
    padding-bottom: @mainContentWithStickyPaginationBottomPadding + @collapsedStepByStepGuideHeight;
    .sticky-pagination-wrapper {
      margin-bottom: calc(1em + @collapsedStepByStepGuideHeight);
    }
  }

  @media print {
    padding-top: @mainContentVerticalPadding !important;
  }

  .ui.menu.large.tabular {
    .item > i.icon.apstra-icon {
      &.apstra-icon-fabric-settings,
      &.apstra-icon-commit-check {
        font-size: @relativeMassive;
        margin-right: @5px;
      }
      &.apstra-icon-virtual,
      &.apstra-icon-arca,
      &.apstra-icon-logical-diff,
      &.apstra-icon-full-nodes-diff {
        font-size: @relativeBig;
      }
      &.apstra-icon-anomaly,
      &.apstra-icon-dci,
      &.apstra-icon-build-errors,
      &.apstra-icon-warning,
      &.apstra-icon-resource-allocation {
        font-size: @relativeLarge;
      }
      &.apstra-icon-policies,
      &.apstra-icon-catalog,
      &.apstra-icon-ct,
      &.apstra-icon-query {
        font-size: @relativeMedium;
      }
      &.apstra-icon-physical,
      &.apstra-icon-tasks {
        font-size: @relativeSmall;
        margin-right: @7px;
      }
      &.apstra-icon-policies,
      &.apstra-icon-fabric-settings,
      &.apstra-icon-resource-allocation {
        &:before {
          vertical-align: baseline;
        }
      }
    }
  }
  .ui.header {
    .ui.big.breadcrumb > span.section {
      i.icon.apstra-icon.apstra-icon-resource {
        font-size: @relativeTiny;
        &:before {
          vertical-align: top;
        }
      }
    }
  }
  .resource-allocation-tree {
    .ui.relaxed.divided.list {
      .ui.item > .content > .header {
        i.icon.apstra-icon {
          vertical-align: text-top;
        }
      }
    }
  }
}

.global-cluster-message {
  @globalClusterMessageColor: @orange;
  @globalClusterMessageBorderStyle: 3px solid @globalClusterMessageColor;
  position: absolute;
  .top, .bottom, .left, .right {
    position: fixed;
    z-index: @layoutZIndex + 1;
  }
  .top, .bottom {
    left: 0;
    right: 0;
  }
  .left, .right {
    top: 0;
    bottom: 0;
  }
  .top {
    top: 0;
    border-top: 0;
    background-color: @globalClusterMessageColor;
    height: @globalClusterMessageHeight;
    color: @white;
    .global-cluster-message-container {
      background-color: @globalClusterMessageColor;
      width: 100%;
      text-align: center;
      &:not(.expanded) {
        height: @globalClusterMessageHeight;
        overflow: hidden;
      }
      a {
        color: @darkWhite;
        font-weight: @bold;
      }
      .ui.list {
        .item {
          height: @globalClusterMessageHeight;
          line-height: @globalClusterMessageHeight;
          padding: 0;
          margin: 0;
        }
        .item-message {
          display: inline-block;
          border-radius: 2px;
          padding: 0 10px;
          line-height: @globalClusterMessageHeight - 2px;
          background: darken(@globalClusterMessageColor, 8%);
        }
      }
    }
    .global-cluster-message-toggle {
      width: 100%;
      text-align: center;
      > .ui.button {
        margin: 0;
        padding-top: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: @12px;
      }
    }
  }
  .bottom {
    bottom: 0;
    border-bottom: @globalClusterMessageBorderStyle;
  }
  .left {
    left: 0;
    border-left: @globalClusterMessageBorderStyle;
  }
  .right {
    right: 0;
    border-right: @globalClusterMessageBorderStyle;
  }
}

.search-container .code-editor-control {
  padding-left: 30px;
}

.ui.menu.four-columns-menu {
  > .item > i.icon {
    font-size: @21px;
    width: @21px;
  }
}
