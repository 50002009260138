@import (reference) '../../styles/variables.less';
@import (reference) '~theme/globals/site.variables';

/* stylelint-disable selector-class-pattern */

.graphql-api-explorer {
  .row:last-child {
    padding-top: 0;
  }
  .graphiql-container {
    height: 80vh;
    border-radius: @defaultBorderRadius;

    .topBar {
      background: @white;
      padding: 2rem;
      flex: 1;
      border-bottom-color: @borderColor;

      .title, .execute-button-wrap {
        display: none;
      }

      .toolbar {
        flex: 1;
        justify-content: space-between;
      }
    }

    .docExplorerShow {
      display: none;
    }

    .doc-explorer-title-bar {
      height: 47px;
    }

    .CodeMirror {
      color: @textColor;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .segment {
    padding: 0;
  }

  .queryWrap {
    .CodeMirror-gutters {
      background-color: @white;
      border-color: @borderColor;
    }

    .secondary-editor-title {
      background-color: @white;
      border-color: @borderColor;

      & div {
        color: @grey9 !important;
        cursor: row-resize !important;
      }
    }

    .CodeMirror-linenumber {
      color: @grey9;
    }

    .CodeMirror-vscrollbar {
      background-color: @white;
    }

    .CodeMirror-hscrollbar {
      background-color: @white;
    }
  }

  .resultWrap {
    border-left-color: @borderColor;

    .CodeMirror {
      background-color: @white;
    }

    .CodeMirror-gutters {
      background-color: @white;
      border-color: @borderColor;
    }
  }
  .dropdown-label {
    background-color: transparent;
  }
}
