@import (reference) '~theme/globals/site.variables';

@speed-1G: @orange;
@speed-10G: @blue;
@speed-25G: @yellow;
@speed-40G: @violet;
@speed-100G: @purple;
@speed-400G:  @olive;

@portSize: 28px;

.asterisk {
  content: "*";
  color: @red;
  margin-left: 2px;
}

.required-field {
  &.required > label:after {
    .asterisk();
  }
}

.field-margins {
  margin: 2px 0 10px 0;

  &:last-child {
    margin-bottom: 0;
  }

  & > label {
    display: block;
    margin-bottom: 2px;
  }
}

.popup-errors {
  margin: 5px 0;
  padding: 10px;
  ul {
    padding: 0 0 0 4px;
  }
}
