.graph-canvas-container {
  position: relative;
  margin: auto;
  .nodes {
    position: absolute;
    top: 0;
    left: 0;
    display: inline;
  }
  .graph-canvas {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    * {
      pointer-events: initial;
    }
    &.dragging {
      cursor: pointer;
      pointer-events: initial;
    }
  }
}
