.stage-data-source-selectors {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .data-source {
    &.ui.form .inline.fields {
      margin: 0;
    }
  }
  .aggregation-input {
    margin-left: 20px;
  }
}
