@import "../variables.less";
@import "../common.less";

.ct-catalog {
  button.item {
    border: none;
    background: transparent;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 2px;
    padding-left: 10px !important;
    padding-right: 10px !important;

    &:hover {
      background-color: @offWhite;
    }

    &:last-child {
      padding-bottom: 0.5em !important;
    }

    &.disabled > div.header {
      color: @eptCatalogDisabledItem;
    }

    &:not(.disabled) {
      cursor: pointer;
      color: @textColor !important;

      & > div.header {
        text-decoration: underline;
      }
    }

    &:not(.highlight) {
      border: solid 2px transparent;
    }

    &.highlight {
      border: solid 2px @eptHighlightBorderColor;
      border-radius: 4px;
      background-color: @eptHighlightColor;

      .header {
        font-weight: 600 !important;
      }
    }

    div.header {
      width: 100%;
      text-align: left;
      padding: 4px 0 2px;
      text-decoration: underline;
    }

    div.content {
      font-size: 80%;
      text-align: left;

      &:not(:empty) {
        margin-top: 2px;
      }

      &.types {
        margin-top: 4px;

        div {
          width: 50%;
          float: left;

          span {
            font-weight: bold;

            &:after {
              content: ': ';
            }
          }
        }
      }
    }
  }
}
