.resource-allocation-tree {
  .active {
    color: @blue !important;
  }
}

.clickable-element {
  cursor: pointer;
  &:hover {
    margin-bottom: 0;
  }
}
