@import (reference) '~theme/globals/site.variables';

.interfaces-map-view {
  .interface-map-statistic {
    .ui.card {
      padding: 10px 20px 0;
      margin: 0;
      border: none;
      box-shadow: none;
      width: auto;

      .ui.statistic {
        padding: 10px;

        &.hovered {
          background: @darkWhite;
        }

        .value, .label {
          text-transform: none;
          cursor: default;
        }
      }
    }
  }

  h4.header.dividing {
    color: @lightTextColor;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;

    .ui.dropdown {
      position: absolute;
      right: 0;
      color: @black;
      font-size: 14px;
      text-transform: none;
    }
  }

  .header {
    i {
      text-transform: none;
      font-weight: 100;
      display: inline-block;
      font-size: 1rem;
    }

    &:not(.sub) > i {
      margin-left: 1em;
    }
  }

  .ui.accordion .title {
    padding: 0;
  }

  .segments {
    > .segment {
      width: 50%;

      .device-summary .panel-summary.compact {
        float: none;
      }

      .panel-summary:last-child {
        margin-bottom: 0;
      }

      .port-map .ui.segment.port {
        line-height: 0.2;
        cursor: pointer !important;

        &:hover {
          filter: brightness(120%);
        }

        .icon.checkmark {
          margin: 0;
          font-size: 0.6em;
          position: absolute;
          top: 6px;
          left: 2px;
        }
      }
    }
  }
}

.interface-map-form {
  table.definition a {
    display: inline-block;
    margin-left: 10px;
  }
}
